/** @jsxImportSource @emotion/react */

import { useTheme, CSSObject, Theme } from '@emotion/react'
import { DeckDateProps } from '../../types/types'

const DeckDateStyles = (theme: Theme): CSSObject => {
  return {
    deckDate: {
      whiteSpace: 'nowrap',
      // color: theme.fontColorMedium,
      // textAlign: 'center',
      // fontStyle: 'italic',
      // paddingTop: theme.remEighth,
      // opacity: 0,
      // animation: `0s linear 0.75s forwards ${fadeIn}`,
      // '@media only screen and (min-width: 768px)': {
      //   paddingTop: theme.remHalf,
      // },
      // '@media only screen and (min-width: 992px)': {
      //   fontSize: theme.fontDesktopButtonSize,
      // },
    },
  }
}

const DeckDate = (props: DeckDateProps) => {
  const theme = useTheme()
  const classes = DeckDateStyles(theme)

  // cache is a string: 2021-01-25T18:10:47.807Z
  if (props.activeDeck.cache) {
    let deckDate
    const dateMatch = /(\d{4})-(\d{2})-(\d{2})/.exec(props.activeDeck.cache)
    if (dateMatch) {
      const [, year, month, day] = dateMatch
      const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ]
      deckDate = `${day} ${monthNames[Number(month) - 1]} ${year}`
    }
    return (
      <>
        <span css={classes.deckDate}>Updated {deckDate}.</span>
      </>
    )
  } else return null
}

export default DeckDate
