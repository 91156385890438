import { CSSObject, Theme } from '@emotion/react'

const ButtonStyles = (theme: Theme): CSSObject => {
  return {
    button: {
      display: 'block',
      whiteSpace: 'nowrap', // for long names
      width: '100%', // for transitioning cover
      fontFamily: theme.fontStackBody,
      fontWeight: theme.fontBodyWeight,
      fontSize: theme.fontButtonSize,
      color: theme.fontColor,
      textAlign: 'left',
      backgroundColor: theme.backgroundColor,
      border: 0,
      cursor: 'pointer',
      margin: 0,
      padding: `${theme.remQuarter} ${theme.remQuarter} ${theme.remQuarter} ${theme.remEighth}`,
      '&:hover': {
        color: theme.fontColorHover,
      },
      '&:focus': {
        outline: 0,
      },
      '&:active': {
        color: theme.fontColor,
      },
      '&.class-header': {
        fontWeight: theme.fontBodyWeightBold,
        cursor: 'default',
        color: theme.fontColor,
      },
      '&.class-header:hover': {
        color: theme.fontColor,
      },
      '&.selected': {
        color: theme.fontColorSelected,
      },
      '@media only screen and (min-width: 992px)': {
        fontSize: theme.fontDesktopButtonSize,
        // padding: `${theme.remQuarter} ${theme.remQuarter} ${theme.remQuarter} ${theme.remQuarter}`,
        padding: theme.remQuarter,
      },
    },

    buttonNameDate: {
      label: 'button-name-date',
      display: 'flex',
      justifyContent: 'space-between',
    },

    concurrence: {
      label: 'concurrence',
      padding: '0 .35em 0 .25em',
      margin: '0 0 0 .5em',
      opacity: 0.8,
      backgroundColor: theme.backgroundColorHighlight,
    },

    date: {
      label: 'date',
      flexGrow: 4,
      textAlign: 'right',
      padding: '0 1em 0 0',
      opacity: 0.7,
    },
  }
}

export default ButtonStyles
