/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react'
import { motion, AnimatePresence } from 'framer-motion'
import MenuStyles from './MenuStyles'
import {
  MenuProps,
  MenuTree,
  MenuTreeItemProps,
  MenuTreeBranch,
} from '../../../types/types'
import Button from './Button'
import mapMetaToMenu from '../../../local_modules/mapMetaToMenu'

// recursive menu adapted from Olio Apps, Jacob Irwin-Cline, https://www.olioapps.com/blog/react-recursion/

const MenuTreeItem = ({
  button,
  children,
  // className,
  isOpen,
}: MenuTreeItemProps) => {
  const theme = useTheme()
  const classes = MenuStyles(theme)

  return (
    <div css={classes.menuTreeItem}>
      <Button
        text={button.text}
        className={button.className}
        onClickAction={button.onClickAction}
        nextDeck={button.nextDeck}
        sort={button.sort}
      />
      <AnimatePresence>
        {isOpen && (
          <motion.div
            css={classes.menuTreeItemChildren}
            // className={isOpen ? 'open' : undefined}
            initial={{ maxHeight: 0 }}
            animate={{ maxHeight: 1000 }}
            exit={{ maxHeight: 0 }}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}

const Menu = ({ meta, sort, activeDeck, handleDeckChange }: MenuProps) => {
  const theme = useTheme()
  const classes = MenuStyles(theme)

  const mappedMeta: MenuTree = mapMetaToMenu(
    meta,
    sort,
    activeDeck,
    handleDeckChange
  )

  const createTreeBranch = (branch: MenuTreeBranch, n: string) =>
    branch.branches && (
      <MenuTreeItem key={n} button={branch.button} isOpen={branch.isOpen}>
        {branch.branches.map((branch: MenuTreeBranch, i: number) => {
          const str_i = i.toString()
          const key_i = n + str_i
          return <div key={key_i}>{createTreeBranch(branch, key_i)}</div>
        })}
      </MenuTreeItem>
    )

  if (mappedMeta.length === 0) {
    return (
      <div css={classes.menu}>
        <div css={classes.loading}>No decks found.</div>
      </div>
    )
  } else {
    return (
      <div css={classes.menu}>
        {mappedMeta.map((branch: MenuTreeBranch, n: number) => {
          const key_n: string = n.toString()
          return (
            <div
              key={key_n}
              css={classes.menuTopBranch}
              className={branch.className}
            >
              {createTreeBranch(branch, key_n)}
            </div>
          )
        })}
      </div>
    )
  }
}

export default Menu
