/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react'
import { CSSObject, Theme } from '@emotion/react'
import { Dispatch, SetStateAction } from 'react'

interface FilterProps {
  filterLabel: string
  filterActive: boolean
  setFilterActive: Dispatch<SetStateAction<boolean>>
}

const FilterStyles = (theme: Theme): CSSObject => {
  return {
    filterDiv: {
      position: 'relative',
      color: theme.fontColor,
      // margin: `${theme.remQuarter} 0 0 ${theme.remFull}`,
      // padding: `${theme.remQuarter} 0 0 ${theme.remHalf}`,
      '@media only screen and (min-width: 992px)': {
        // margin: `${theme.remHalf} 0 ${theme.remHalf} ${theme.remFull}`,
        // padding: 0,
        fontSize: theme.fontDesktopButtonSize,
      },
    },
    filterCheckbox: {
      margin: `${theme.remHalf} ${theme.remHalf} 0 ${theme.remFull}`,
      '@media only screen and (min-width: 992px)': {
        margin: `${theme.remTwothird} ${theme.remHalf} 0 ${theme.remFull}`,
      },
    },
    label: {
      position: 'absolute',
      // padding: `0 0 ${theme.remQuarter} 0`,
      // lineHeight: `2.5`,
      top: `${theme.remHalf}`,
      // '@media only screen and (min-width: 992px)': {
      //   top: `${theme.remHalf}`,
      // border: '1px solid red',
      // top: -2,
      // padding: `0 0 0 ${theme.remHalf}`,
      // margin: `0 0 ${theme.remFull} 0`,
      // },
    },
  }
}

const Filter = ({
  filterLabel,
  filterActive,
  setFilterActive,
}: FilterProps) => {
  const theme = useTheme()
  const classes = FilterStyles(theme)

  return (
    <div css={classes.filterDiv}>
      <input
        css={classes.filterCheckbox}
        type="checkbox"
        id="filter"
        checked={filterActive}
        onChange={() => setFilterActive(!filterActive)}
      />
      <label css={classes.label} htmlFor="filter">
        {filterLabel}
      </label>
    </div>
  )
}

export default Filter
