/** @jsxImportSource @emotion/react */
import { DeckSubHeaderProps } from '../../types/types'
import { useTheme, keyframes, Theme, CSSObject } from '@emotion/react'
import DeckDate from './DeckDate'

const DeckSubHeaderStyles = (theme: Theme): CSSObject => {
  const fadeIn = keyframes({
    to: { opacity: 1 },
  })
  return {
    deckSubHeader: {
      margin: `0 ${theme.remTwothird} 0 ${theme.remTwothird}`,
      color: theme.fontColorMedium,
      textAlign: 'center',
      fontStyle: 'italic',
      paddingTop: theme.remEighth,
      opacity: 0,
      animation: `0s linear 0.75s forwards ${fadeIn}`,
      '@media only screen and (min-width: 768px)': {
        paddingTop: theme.remHalf,
      },
      '@media only screen and (min-width: 992px)': {
        fontSize: theme.fontDesktopButtonSize,
      },
    },
  }
}

const DeckSubHeader = (props: DeckSubHeaderProps) => {
  const theme = useTheme()
  const classes = DeckSubHeaderStyles(theme)

  let links = null
  if (props.activeDeck.concurrence && props.activeDeck.concurrence.length > 0) {
    links = props.activeDeck.concurrence.map((streamer, i) => {
      if (!props.activeDeck.concurrence) return null // resolves Typescript error
      return (
        <span key={streamer.name}>
          <a key={streamer.name} href={streamer.url}>
            {streamer.name}
          </a>
          {i < props.activeDeck.concurrence.length - 1 ? ', ' : '. '}
        </span>
      )
    })
  }

  return (
    <div css={classes.deckSubHeader}>
      {links ? 'Also played by ' : ''} {links}
      <DeckDate activeDeck={props.activeDeck} />
    </div>
  )
}

export default DeckSubHeader
