import buildModelDeck from './modelDeckBuild'

const handleModelDeck = (cards: any[], deckcode?: string) => {
  try {
    // check for missing deck
    if (!deckcode) {
      throw new Error('Error: handleModelDeck')
    }
    return buildModelDeck(cards, deckcode)
  } catch (err) {
    // const errorDeckcode = 'AAECAf0GAAGPnwQA' // 2 Hellfire cards
    const errorDeckcode = 'AAECAf0GAY+fBAAA' // error deck: 1 Hellfire card
    return buildModelDeck(cards, errorDeckcode)
  }
}

export default handleModelDeck
