import findDeck from './findDeck'
import makeNewURL from './makeNewURL'
import initDeck from './initDeck'
import setMetaFromSources from './setMetaFromSources'

import {
  State,
  Meta,
  MetaSources,
  SortOptions,
  SortDecks,
  Deck,
  Sort,
  StateHistory,
  FindDeckResults,
} from '../types/types'
import { Dispatch, SetStateAction } from 'react'

const setInitialState = ({
  urlDeckQuery = null,
  metaSources,
  sortOptions,
  sortDecks,
  setStateHistory,
}: {
  urlDeckQuery: { queryArchetype: string; queryName: string } | null
  metaSources: MetaSources
  sortOptions: SortOptions
  sortDecks: SortDecks
  // FIXME: Why do I need undefined here?
  setStateHistory: Dispatch<SetStateAction<StateHistory | undefined>>
}): State => {
  let sort: Sort = sortOptions[0]
  let meta: Meta = []
  let activeDeck = {} as Deck

  if (localStorage && localStorage.sort)
    sort = localStorage.getItem('sort') as Sort

  const metaSourcesPlayers = []
  metaSourcesPlayers.push(metaSources.streamer)
  if (metaSources.master) metaSourcesPlayers.push(metaSources.master)

  if (urlDeckQuery) {
    const deckResults: FindDeckResults = findDeck(
      urlDeckQuery,
      metaSources.archetype,
      ...metaSourcesPlayers
    )
    if (deckResults.sort) {
      sort = deckResults.sort
    }
    activeDeck = { ...deckResults.deck }
    if (deckResults.meta) meta = [...deckResults.meta]
  }

  if (sort === 'Master' && !metaSources.master) {
    sort = sortOptions[0]
    if (typeof Storage !== 'undefined') {
      localStorage.setItem('sort', sort)
    }
  }

  if (!meta.length) meta = setMetaFromSources(metaSources, sort)
  if (!activeDeck.name)
    activeDeck =
      sort === 'Streamer'
        ? initDeck(metaSources.streamer, sort, sortDecks)
        : sort === 'Master' && metaSources.master
        ? initDeck(metaSources.master, sort, sortDecks)
        : initDeck(metaSources.archetype, sort, sortDecks)

  const newStateHistoryItem = {
    activeDeck,
    sort,
  }

  setStateHistory({ index: 0, states: [newStateHistoryItem] })

  document.title = 'Full Meta Jackal - ' + activeDeck.name

  const newURL = makeNewURL(activeDeck, sort)

  const name =
    sort === 'Streamer' || sort === 'Master' ? activeDeck.name : activeDeck.src
  window.history.replaceState(
    {
      index: 0,
      archetype: activeDeck.archetype,
      name,
      sort,
    },
    '', // typescript requires a string here instead of null
    newURL
  )

  // FIXME: Do I need a fallback date, or to handle undefined metaDate?
  // let metaDate
  // if (metaSources.archetype && metaSources.archetype.date) {
  //   metaDate = metaSources.archetype.date[0]
  // }

  const initialState = {
    sort,
    meta,
    activeDeck,
  }
  return initialState
}

export default setInitialState
