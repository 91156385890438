import { useState, useEffect, useRef } from 'react'
/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react'
import CopyDeckcodeStyles from './CopyDeckcodeStyles'
import copyIcon from '../images/copy-icon.svg'
import { CopyDeckProps } from '../../types/types'

const CopyDeck = ({ activeDeck }: CopyDeckProps) => {
  const theme = useTheme()
  const classes = CopyDeckcodeStyles(theme)

  const [copied, setCopied] = useState(false)

  useEffect(() => {
    setCopied(false)
  }, [activeDeck])

  const textAreaEltRef = useRef<HTMLTextAreaElement>(null)

  const copyToClipboard = () => {
    if (textAreaEltRef.current) {
      // Include deck name in string for clipboard
      const editedName = activeDeck.src + ' ' + activeDeck.name
      const inputDeckNameHeader = '### ' + editedName + '\n'
      const inputDeckcode = activeDeck.deckcode
      textAreaEltRef.current.value = inputDeckNameHeader + inputDeckcode
      textAreaEltRef.current.select()
      document.execCommand('copy')
      setCopied(true)
    }
  }

  return (
    <div css={classes.deckcodeContainer}>
      <form>
        <textarea // Currently rendered off screen
          css={classes.deckcodeTextarea}
          placeholder="" // FIXME: Handle mobile users who can't hit return
          ref={textAreaEltRef}
          // onChange={(event) => handleInputChange(event)}
          value={activeDeck.deckcode}
          readOnly
        />
        <button
          type="button"
          css={classes.deckcodeButton}
          onClick={() => copyToClipboard()}
        >
          {copied ? 'Copied!' : 'Copy Deck'}
          <img css={classes.deckcodeButtonImg} src={copyIcon} alt="Copy" />
        </button>
      </form>
    </div>
  )
}

export default CopyDeck
