import {
  Meta,
  Sort,
  MenuTree,
  MenuTreeBranch,
  Deck,
  ArchClass,
  HandleDeckChange,
  MetaArchetype,
  MetaDeck,
} from '../types/types'

interface ClassSort {
  [key: string]: any[]
}

// FIXME and TODO: any[]
const mapToMenuData = (
  meta: Meta,
  sort: Sort,
  activeDeck: Deck,
  handleDeckChange: HandleDeckChange
): MenuTree => {
  let menuData = []

  if (/Class/gi.test(sort)) {
    menuData = makeHSClassMenus(meta)
  } else {
    menuData = makeArchetypeMenus(meta)
  }
  return menuData

  function makeHSClassMenus(meta: Meta): MenuTree {
    const hsClassMenus = []
    // sort archetypes into their HS classes; return an object
    const classSort = meta.reduce<ClassSort>((hsClasses, arch) => {
      const { archClass } = arch
      hsClasses[archClass] = hsClasses[archClass] ?? []
      hsClasses[archClass].push(arch)
      return hsClasses
    }, {})

    for (const archClass in classSort) {
      const text = archClass

      const isOpen = archClass === activeDeck.archClass

      let classNameBranch = archClass.toLowerCase().replace(/\s+/g, '')
      if (isOpen) classNameBranch += ' open'

      let classNameButton = 'class-header'
      if (archClass === activeDeck.archClass) classNameButton += ' selected'

      const branches = makeArchetypeMenus(classSort[archClass])

      const hsClass = {
        button: {
          text,
          className: classNameButton,
        },
        branches,
        className: classNameBranch,
        isOpen: true, // HSClass menus are always open
      }

      hsClassMenus.push(hsClass)
    }
    return hsClassMenus
  }

  function makeArchetypeMenus(meta: Meta): MenuTree {
    const menuData = meta.map((arch: MetaArchetype) => {
      // button
      const text = arch.name
      const classNameButton =
        arch.name === activeDeck.archetype ? 'selected' : undefined

      const deck: MetaDeck = arch.decks[0]
      const root = sort === 'Streamer' || sort === 'Master' ? deck : arch

      const isDeck = (root: MetaDeck | MetaArchetype): root is MetaDeck =>
        (root as MetaDeck).src !== undefined

      const name = root.name
      const deckcode = root.deckcode
      const cache = root.cache
      let src, url, concurrence
      if (isDeck(root)) {
        src = root.src
        url = root.url
        concurrence = root.concurrence
      }
      const nextDeck = {
        name,
        deckcode,
        archetype: arch.name,
        archClass: arch.archClass,
        cache,
        src,
        url,
        concurrence,
      }

      // branch
      const isOpen = arch.name === activeDeck.archetype
      let classNameBranch = arch.archClass.toLowerCase().replace(/\s+/g, '')
      if (isOpen) classNameBranch += ' open'

      const branches = makeDeckMenus(arch.decks, arch.name, arch.archClass)

      const menuSubData: MenuTreeBranch = {
        button: {
          text,
          className: classNameButton,
          nextDeck,
          sort,
          onClickAction: () => handleDeckChange(nextDeck),
        },
        className: classNameBranch,
        isOpen,
        branches,
      }
      return menuSubData
    })

    return menuData
  }

  function makeDeckMenus(
    decks: any[],
    archetype: string,
    archClass: ArchClass
  ) {
    const deckMenus = decks.map((d: any) => {
      const classNameButton =
        d.deckcode === activeDeck.deckcode ? 'selected' : undefined

      // special naming for Hs Replay and VS decks
      let text =
        sort !== 'Streamer' && sort !== 'Master'
          ? d.player
            ? d.src
            : d.src === 'HS Replay'
            ? 'Popular Deck'
            : d.src === 'Vicious Syndicate'
            ? 'VS Deck'
            : d.name
          : d.name

      let date
      const dateMatch = /\d{4}-(\d{2})-(\d{2})/.exec(d.cache)
      if (dateMatch) {
        const [, monthMatch, dayMatch] = dateMatch
        // strip leading 0s
        const month = parseInt(monthMatch).toString()
        const day = parseInt(dayMatch).toString()
        date = `${month}-${day}`
      }

      const nextDeck = {
        name: d.name,
        deckcode: d.deckcode,
        archetype,
        archClass,
        cache: d.cache,
        date,
        src: d.src,
        url: d.url,
        concurrence: d.concurrence,
      }

      const deckMenu: MenuTreeBranch = {
        button: {
          text,
          className: classNameButton,
          nextDeck,
          sort,
          onClickAction: () => handleDeckChange(nextDeck),
        },
        branches: [], // must include branches prop with empty array for recursion to work
        isOpen: false, // always false for deck menus
      }

      return deckMenu
    })

    return deckMenus
  }
}

export default mapToMenuData
