import { CSSObject, Theme } from '@emotion/react'

const SidebarStyles = (theme: Theme): CSSObject => {
  return {
    hrSolid: {
      // border: 0,
      // borderBottom: theme.border,
      // border props set by Global Styles
      margin: `${theme.remHalf} -9999rem 0 -9999rem`,
      padding: `${theme.remHalf} 9999rem 0 9999rem`,
      '@media only screen and (min-width: 992px)': {
        marginTop: theme.remThreefourth,
      },
    },
    containerSidebar: {
      label: 'container-sidebar',
      position: 'relative',
      display: 'block',
      width: 0,
      height: 0,
      '@media only screen and (min-width: 768px)': {
        width: 190,
      },
      '@media only screen and (min-width: 992px)': {
        width: 220,
      },
      '&.open': {
        zIndex: 3,
        position: 'absolute',
        width: '100%',
        height: '100%',
        '@media only screen and (min-width: 768px)': {
          width: 190,
        },
        '@media only screen and (min-width: 992px)': {
          width: 220,
        },
      },
    },
    sidebar: {
      label: 'sidebar',
      position: 'fixed',
      display: 'block',
      zIndex: 3,
      top: 44,
      left: '-190px',
      bottom: 0, // Scrolling in mobile breaks without this!
      width: 190,
      // overflowX: 'hidden', // FIXME: Is this necessary?
      margin: 0,
      backgroundColor: theme.backgroundColor,
      borderRight: theme.border,
      transition: 'left .2s ease-out',
      '&.open': {
        position: 'fixed',
        left: 0,
        boxShadow: '0 0 6px rgba(0, 0, 0, .5)',
        transition: 'left .2s ease-out',
      },
      '@media only screen and (min-width: 768px)': {
        // position: 'fixed',
        zIndex: 1,
        left: 0,
        bottom: 0,
        top: 55,
        width: 190,
        padding: 0,
        boxShadow: '0 0 10px rgba(0, 0, 0, .3)',
      },
      '@media only screen and (min-width: 992px)': {
        top: 72,
        width: 220,
      },
    },
    /**
     * sidebarScroller is required:
     * An element with overflow scrolling but without explicit positioning
     * is needed for Safari. Otherwise, Safari clips the Loading component
     * called by Button's useTransition.
     *
     * height: calc px value must be set to account for height of elements above sidebarScroller
     * Filter component is about 35px high
     */
    sidebarScroller: {
      label: 'sidebar-scroller',
      // height: '100%', // required
      height: 'calc(100% - 84px)', // no callout
      // height: 'calc(100% - 146px)', // callout
      overflowY: 'scroll',
      overflowX: 'hidden',
      scrollbarColor: `${theme.scrollbarColorThumb} ${theme.scrollbarColorTrack}`,
      scrollbarWidth: 'thin',
      '&::-webkit-scrollbar': {
        backgroundColor: theme.backgroundColor,
        height: theme.remHalf,
        width: theme.remHalf,
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.scrollbarColorThumb,
        height: theme.remHalf,
        width: theme.remHalf,
        border: theme.scrollbarBorderThumb,
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: theme.scrollbarColorTrack,
        height: theme.remHalf,
        width: theme.remHalf,
        border: theme.scrollbarBorderTrack,
      },
      '@media only screen and (min-width: 768px)': {
        height: 'calc(100% - 85px)', // no callout
        // height: 'calc(100% - 146px)', // callout
      },
      '@media only screen and (min-width: 992px)': {
        height: 'calc(100% - 98px)', // no callout; needs to be %, not vh, should match top/header height
        // height: 'calc(100% - 166px)', // callout
      },
    },
    sidebarCloser: {
      label: 'sidebar-closer',
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 2,
      '@media only screen and (min-width: 768px)': {
        display: 'none',
        width: 0,
        height: 0,
      },
      '&.open': {
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(0, 0, 0, .2)',
        transition: 'background-color .2s ease-out', // FIXME?
      },
    },
    menuButton: {
      label: 'menu-button',
      position: 'fixed',
      zIndex: 4,
      top: 3,
      right: 8,
      alignSelf: 'end',
      justifySelf: 'center',
      fontFamily: theme.fontStackBody,
      fontWeight: theme.fontBodyWeightBold,
      padding: `${theme.remEighth} ${theme.remEighth} .05rem ${theme.remEighth}`,
      margin: 0,
      cursor: 'pointer',
      transform: 'translateY(0)',
      transition: 'transform 0.2s ease',
      '&.hidden': {
        transform: ' translateY(-110%)',
      },
      '@media only screen and (min-width: 768px)': {
        display: 'none',
      },
    },
    menuButtonImg: {
      label: 'menu-button-img',
      height: 32,
      // set svg to font color
      filter: theme.filter,
    },
  }
}

export default SidebarStyles
