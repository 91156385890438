import {
  useCallback,
  useReducer,
  useEffect,
  useState,
  useMemo,
  ChangeEvent,
} from 'react'
/** @jsxImportSource @emotion/react */
import { useTheme, Global } from '@emotion/react'
import DeckViewer from './components/deck/DeckViewer'
import Sidebar from './components/sidebar/Sidebar'
import GlobalStyles from './GlobalStyles'
import initDeck from './local_modules/initDeck'
import setInitialState from './local_modules/setInitialState'
// import handleDeckChangeModule from './local_modules/handleDeckChangeModule'
import setMetaFromSources from './local_modules/setMetaFromSources'
import makeNewURL from './local_modules/makeNewURL'
import findDeck from './local_modules/findDeck'

import {
  Meta,
  MetaSources,
  ProviderProps,
  Deck,
  Sort,
  SortOptions,
  State,
  StateHistory,
  FindDeckResults,
} from './types/types'

/**
 * fetchAPI
 *
 * For Suspense/Concurrent Mode
 * Returns a function that throws a Promise and returns an array
 */
import fetchAPI from './local_modules/fetchAPI'

// When Master is disabled,
// remember to run create-meta without Master source
const masterEnabled = false

/**
 * Apis
 */
// use server root path for fetch
const apiCardsCurrent = '/cards-current.json'
const apiArchetype = '/meta.json'
const apiStreamer = '/meta-streamers.json'
const apiMaster = '/meta-masters.json'

// fetchAPI fetches the resource; handleFetch handles the status of the request and returns the data
// All json needs to be fetched at the start for findDeck with URL
// (ie to find a streamer using url with streamer name)
const handleFetch_cardsCurrent = fetchAPI(apiCardsCurrent)
const handleFetch_archetype = fetchAPI(apiArchetype)
const handleFetch_streamer = fetchAPI(apiStreamer)
const handleFetch_master = masterEnabled ? fetchAPI(apiMaster) : undefined

const Provider = ({
  themeFlavor,
  cardSize,
  deckView,
  sortDecks,
  windowWidth,
  footerVisible,
  setFooterVisible,
}: ProviderProps) => {
  const cardsCurrent = handleFetch_cardsCurrent()

  const metaSources = useMemo(() => {
    const sources: MetaSources = {
      archetype: handleFetch_archetype(),
      streamer: handleFetch_streamer(),
    }
    if (handleFetch_master) sources.master = handleFetch_master()
    return sources
  }, [])

  const sortOptions = useMemo(() => {
    const options: SortOptions = [
      'Archetype Rank',
      'Archetype Name',
      'Class Rank',
      'Class Name',
      'Streamer',
    ]
    if (masterEnabled) options.push('Master')
    return options
  }, [])

  /**
   * URL
   */
  const urlDeckQuery = useMemo(() => {
    if (window.location.pathname.length > 1) {
      const pathArray = window.location.pathname.split('/')
      const newDeckQuery = {
        queryArchetype: pathArray[1],
        queryName: pathArray[2],
      }
      return newDeckQuery
    }
    return null
  }, [])

  /**
   * metaDate
   */
  const metaDate: string | undefined = useMemo(() => {
    if (metaSources.archetype.date) return metaSources.archetype.date[0]
  }, [metaSources.archetype.date])

  /**
   * rankStartDate
   * string: 2021-01-25T18:10:47.807Z
   */
  const rankStartDate: string | undefined = useMemo(() => {
    const dateMatch = /(\d{4})-(\d{2})-(\d{2})/.exec(
      metaSources.archetype[0]['rankStartDate']
    )
    console.log(metaSources.archetype[0]['rankStartDate'])
    if (dateMatch) {
      const [, year, month, day] = dateMatch
      const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ]
      return `${day} ${monthNames[Number(month) - 1]} ${year}`
    }
  }, [metaSources.archetype])

  /**
   * Filter to new expansion option
   * Filter.js, is not always enabled in Sidebar
   */
  // FIXME: Change from newExpansion or miniset to 'latestCards'
  // filterShow: 'newExpansion', 'miniset' or null to disable
  // date string (14 characters: '2022-01-25T17:00:00') is disabled for now
  const filterShow = null
  const filterLabel = 'Knights of the Frozen Throne'
  const [filterActive, setFilterActive] = useState<boolean>(false)

  /**
   * Search
   */
  const [searchText, setSearchText] = useState('')
  const handleSearch = (e: ChangeEvent) => {
    setSearchText((e.target as HTMLInputElement).value)
  }

  // stateHistory and stateHistoryIndex are for browser history
  // stateHistory doesn't save entire state, only activeDeck and sort
  // always test stateHistory against null since stateHistory can === 0
  const [stateHistory, setStateHistory] = useState<StateHistory>()

  /**
   * state
   */
  const reducer = (currentState: State, newState: State) => {
    const meta = setMetaFromSources(metaSources, newState.sort)

    const state = {
      ...currentState,
      activeDeck: newState.activeDeck,
      sort: newState.sort,
      meta,
    }

    return state
  }

  const initArgs = {
    urlDeckQuery,
    metaSources,
    sortOptions,
    sortDecks,
    setStateHistory,
  }

  const [state, dispatch] = useReducer(reducer, initArgs, setInitialState)

  const handleDeckChange = useCallback(
    (
      activeDeck: Deck,
      sort: Sort = state.sort,
      meta: Meta = state.meta,
      historyIndex: number | null = null,
      fromPopstate: boolean = false
    ) => {
      if (!fromPopstate) handleStateHistory(activeDeck, sort, historyIndex)
      document.title = 'Full Meta Jackal - ' + activeDeck.name
      dispatch({ ...state, activeDeck, sort, meta })

      function handleStateHistory(
        activeDeck: Deck,
        sort: Sort = state.sort,
        historyIndex: number | null = null
      ) {
        if (stateHistory) {
          if (
            historyIndex === null &&
            stateHistory.index !== stateHistory.states.length - 1
          ) {
            // new deck; not at end of stateHistory; start a new stateHistory from index
            historyIndex = stateHistory.index + 1
            setStateHistory({
              index: historyIndex,
              states: [
                ...stateHistory.states.slice(0, stateHistory.index + 1),
                { activeDeck, sort },
              ],
            })
          } else if (historyIndex === null) {
            // new deck
            historyIndex = stateHistory.index + 1
            setStateHistory({
              index: historyIndex,
              states: [...stateHistory.states, { activeDeck, sort }],
            })
          } else if (historyIndex !== null)
            // browser back/forward doesn't change history, just index
            setStateHistory({
              index: historyIndex,
              states: [...stateHistory.states],
            })
        }

        const newURL = makeNewURL(activeDeck, sort)

        if (!fromPopstate) {
          const name = sort === 'Streamer' ? activeDeck.name : activeDeck.src
          window.history.pushState(
            {
              index: historyIndex,
              archetype: activeDeck.archetype,
              name,
              sort,
            },
            '', // typescript requires a string here (docs often use null)
            newURL
          )
        }
      }
    },
    [state, stateHistory]
  )

  const handleSortChange = useCallback(
    (e: ChangeEvent) => {
      const sort: Sort = (e.target as HTMLInputElement).value as Sort
      if (
        (state.sort !== 'Streamer' && sort === 'Streamer') ||
        (state.sort === 'Streamer' && sort !== 'Streamer') ||
        (state.sort !== 'Master' && sort === 'Master') ||
        (state.sort === 'Master' && sort !== 'Master')
      ) {
        // sort changes meta, so active deck must change
        const meta: Meta = setMetaFromSources(metaSources, sort)
        // handle sort changes; not at end of history
        handleDeckChange(initDeck(meta, sort, sortDecks), sort, meta, null)
      }
      // sort does not change meta, deck stays the same
      // FIXME: Why call handleDeckChange here? because stateHistory needs to change
      // and handleDeckChange also handles stateHistory
      else {
        handleDeckChange(state.activeDeck, sort, state.meta, null)
      }

      if (typeof Storage !== 'undefined') {
        localStorage.setItem('sort', sort)
      }
    },
    [
      handleDeckChange,
      metaSources,
      sortDecks,
      state.activeDeck,
      state.meta,
      state.sort,
    ]
  )

  useEffect(() => {
    const handlePopstate = (e: PopStateEvent) => {
      // checking for e.state prevents rage click errors
      if (e.state && e.state.index !== null) {
        let nextIndex = e.state.index
        // If a user rage clicks foward button after back button has unloaded app,
        // browser's forward history may be loaded with a new app without stateHistory.
        // Testing stateHistory[nextIndex] prevents this.
        if (
          stateHistory &&
          stateHistory.states &&
          stateHistory.index !== undefined && // stateHistoryIndex can be 0
          stateHistory.states[nextIndex] &&
          stateHistory.index !== nextIndex &&
          nextIndex !== null &&
          nextIndex >= 0 &&
          nextIndex <= stateHistory.index + 1
        ) {
          // browser back or forward was clicked
          const sort = stateHistory.states[nextIndex].sort
          handleDeckChange(
            stateHistory.states[nextIndex].activeDeck,
            sort,
            setMetaFromSources(metaSources, sort),
            nextIndex,
            true
          )
          if (typeof Storage !== 'undefined') localStorage.setItem('sort', sort)
        } else {
          // Handle no stateHistory;
          // Handles page reloads and anytime app exits browser
          // and browser still has saved url history;
          // Loads a deck from url
          const getUrlDeckQueryPopstate = () => {
            const pathArray = window.location.pathname.split('/')
            const newDeckQuery = {
              queryArchetype: pathArray[1],
              queryName: pathArray[2],
            }
            return newDeckQuery
          }
          const urlDeckQueryPopstate = getUrlDeckQueryPopstate()
          const newActiveDeckPopstate: FindDeckResults = findDeck(
            urlDeckQueryPopstate,
            metaSources.archetype,
            metaSources.streamer
          )
          if (newActiveDeckPopstate.deck) {
            if (!newActiveDeckPopstate.sort)
              newActiveDeckPopstate.sort = sortOptions[0]
            if (!newActiveDeckPopstate.meta)
              newActiveDeckPopstate.meta = metaSources.archetype
            handleDeckChange(
              newActiveDeckPopstate.deck,
              newActiveDeckPopstate.sort,
              newActiveDeckPopstate.meta,
              null,
              true
            )
          }
        }
      }
    }

    window.addEventListener('popstate', handlePopstate)
    return () => window.removeEventListener('popstate', handlePopstate)
  }, [handleDeckChange, metaSources, stateHistory, sortOptions])

  const theme = useTheme()
  const globalStyles = GlobalStyles(theme)

  if (state && state.meta && state.activeDeck) {
    return (
      <>
        <Global styles={globalStyles} />
        <Sidebar
          cardsCurrent={cardsCurrent}
          meta={state.meta}
          activeDeck={state.activeDeck}
          filterShow={filterShow}
          filterActive={filterActive}
          filterLabel={filterLabel}
          setFilterActive={setFilterActive}
          handleSortChange={handleSortChange}
          sort={state.sort}
          sortOptions={sortOptions}
          sortDecks={sortDecks}
          handleDeckChange={handleDeckChange}
          // headerVisible={props.headerVisible}
          searchText={searchText}
          handleSearch={handleSearch}
        />
        <DeckViewer
          cardsCurrent={cardsCurrent}
          activeDeck={state.activeDeck}
          sort={state.sort}
          themeFlavor={themeFlavor}
          cardSize={cardSize}
          metaDate={metaDate}
          rankStartDate={rankStartDate}
          windowWidth={windowWidth}
          deckView={deckView}
          // setHeaderVisible={props.setHeaderVisible}
          setFooterVisible={setFooterVisible}
          footerVisible={footerVisible}
        />
      </>
    )
  } else {
    return null
  }
}

export default Provider
