import buildModelDeck from './modelDeckBuild'
import { Meta, CardCollection } from '../types/types'

const searchMeta = (
  metaUnfiltered: Meta,
  cardsCurrent: CardCollection,
  searchText: string
) => {
  let meta = []

  meta = []
  for (const archetype of metaUnfiltered) {
    const decksFiltered = []
    for (const deck of archetype.decks) {
      if (deck.deckcode) {
        const modelDeck = buildModelDeck(cardsCurrent, deck.deckcode).flat(2)
        // remove duplicate cards
        const cards = [...new Set(modelDeck.map((card) => card.name))]
        const searchTextEsc = searchText.replace(/[\\[.+*?(){|^$]/g, '\\$&')
        const regex = new RegExp(searchTextEsc, 'gi')
        // search for archetype names, card names, streamers
        if (regex.test(deck.name)) decksFiltered.push(deck)
        else if (regex.test(deck.src)) decksFiltered.push(deck)
        else if (cards.some((card) => regex.test(card)))
          decksFiltered.push(deck)
      }
    }
    if (decksFiltered.length) {
      const archetypeFiltered = { ...archetype }
      archetypeFiltered.decks = [...decksFiltered]
      meta.push(archetypeFiltered)
    }
  }
  return meta
}

export default searchMeta
