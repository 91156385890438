import { decode } from 'deckstrings'
import { CardCollection } from '../types/types'

// This is the Model side of Deck creation
// buildModelDeck returns a formatted model deck array to pass to ViewDeck
// Returned model deck is an array of arrays
// Each row in array will be a row in the DOM of ViewDeck
// Each row's array contain an object which represents a card's data
// A card pair is represented by duplicate objects

// For an example of a returned Model Deck see modelDeckExample.json

// deckstrings example deck object, returned from decode:
// {
//   cards: [
//     [ 48, 2 ],    [ 242, 2 ],
//     [ 289, 2 ],   [ 648, 2 ],
//     [ 739, 2 ],   [ 974, 2 ],
//     [ 1090, 2 ],  [ 53173, 2 ],
//     [ 53885, 2 ], [ 54269, 2 ],
//     [ 55161, 2 ], [ 56112, 2 ],
//     [ 56501, 2 ], [ 56502, 2 ],
//     [ 56519, 2 ]
//   ],
//   heroes: [ 51834 ],
//   format: 2,
//   sideboard: [ [ 85604, 1, 90749 ], [ 86383, 1, 90749 ], [ 97112, 1, 90749 ] ]
// }

const buildModelDeck = (cardCollection: CardCollection, deckcode: string) => {
  const deckStringsDeck = decode(deckcode)
  const deck = []
  for (const [id, num] of deckStringsDeck.cards) {
    const set = []
    set.push(getObjects(cardCollection, 'dbfId', id))
    if (num > 1) set.push(getObjects(cardCollection, 'dbfId', id)) // deck needs two copies of card
    deck.push(set)
  }
  const formattedDeck = formatDeck(deck)
  return formattedDeck

  function getObjects(arr: any[], key: string, val: number) {
    for (const obj of arr) {
      if (obj[key] === val) {
        return obj
      }
    }
  }

  function formatDeck(deck: any) {
    deck.sort((a: any[], b: any[]) => {
      const aName = a[0].name.toUpperCase()
      const bName = b[0].name.toUpperCase()
      return aName.localeCompare(bName)
    })
    deck.sort((a: any[], b: any[]) => a[0].cost - b[0].cost)
    let formattedDeck = formatRowsByCost(deck)
    return formattedDeck

    function formatRowsByCost(deck: any[]) {
      let filteredDeck = deck.slice()
      const sortedDeck: any[] = []
      while (filteredDeck.length > 0) {
        filteredDeck = filter(filteredDeck)
      }
      return sortedDeck

      function filter(deck: any[]) {
        const row = deck.filter(
          (cardSet) => Number(cardSet[0].cost) === deck[0][0].cost
        )
        sortedDeck.push(row)
        const filteredDeck = deck.filter(
          (cardSet) => Number(cardSet[0].cost) !== deck[0][0].cost
        )
        return filteredDeck
      }
    }
  }
}

export default buildModelDeck
