import { Meta } from '../types/types'

interface JSONResponse {
  headers: any
}

/**
 * fetchAPI
 *
 * For Suspense/Concurrent Mode
 * Returns a function that throws a Promise that resolves to an array
 */
const fetchAPI = (api: string) => {
  let status = 'pending'
  let meta: Meta = []

  const doFetchAPI = (async function () {
    const response = await fetch(api)
    const json = await response.json()
    meta = [...json]
    meta.date = await getApiDate(response) // adds property date to array as an array
    status = 'resolved'
  })()

  return () => {
    if (status === 'pending') throw doFetchAPI
    return meta
  }

  function getApiDate(response: JSONResponse) {
    const lastMod = response.headers.get('Last-Modified')
    const dateStr = lastMod.toString()
    // const regExp = /\D+\s\d+\s\D+\s\d+/ // includes day of week
    const regExp = /\d+\s\D+\s\d+/ // does not include day of week
    const trimDate = dateStr.match(regExp)
    return trimDate
  }
}

export default fetchAPI
