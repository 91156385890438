import { Meta, MetaDeck, SortDecks, Deck, Sort } from '../types/types'

// meta is already sorted by 'Archetype Rank' for meta
// sortMeta handles sorts by 'Class Rank', 'Class Name' and 'Archetype Name'

const sortDecks = (unsortedMeta: Meta, activeSortDecks: SortDecks) => {
  let meta = unsortedMeta.slice()
  if (activeSortDecks === 'concurrence') {
    meta = unsortedMeta.map((archetype) => {
      archetype.decks.sort((a: Deck, b: Deck) => {
        // prevent undefined error reading length of concurrence
        // some decks, i.e. Popular Deck from HS Replay don't have concurrence
        let aConcur = 0
        let bConcur = 0
        if (a.concurrence) aConcur = a.concurrence.length
        if (b.concurrence) bConcur = b.concurrence.length
        return bConcur - aConcur
      })
      return archetype
    })
  } else {
    // date
    meta = unsortedMeta.map((archetype) => {
      archetype.decks.sort((a: MetaDeck, b: MetaDeck) => {
        // cache is a string: 2021-01-25T18:10:47.807Z
        // VS cache is        2022-01-06T17:26:55+00:00
        // substring to 14th place handles VS cache
        // FIXME: Awkward test and return after typescript?
        if (a.cache && b.cache) {
          const aCache = a.cache.replace(/[-T:]/g, '')
          const bCache = b.cache.replace(/[-T:]/g, '')
          const aDate = Number(aCache.substring(0, 14))
          const bDate = Number(bCache.substring(0, 14))
          return bDate - aDate
        }
        return 0 // FIXME
      })
      return archetype
    })
  }
  return meta
}

const sortMeta = (
  unsortedMeta: Meta,
  activeSort: Sort,
  activeSortDecks: SortDecks = 'date'
) => {
  let date = unsortedMeta.date // capture date from getApi for Footer
  let meta: Meta = unsortedMeta.slice()
  if (activeSort === 'Archetype Rank') {
    meta.sort((a, b) => a.rank - b.rank)
  } else if (activeSort === 'Class Rank') {
    meta
      .sort((a, b) => {
        const aName = a.archClass.toUpperCase()
        const bName = b.archClass.toUpperCase()
        return aName.localeCompare(bName)
      })
      .sort((a, b) => a.classRank - b.classRank)
  } else if (activeSort === 'Class Name') {
    meta.sort((a, b) => {
      const aName = a.archClass.toUpperCase()
      const bName = b.archClass.toUpperCase()
      return aName.localeCompare(bName)
    })
  } else if (
    activeSort === 'Archetype Name' ||
    activeSort === 'Streamer'
    //KLUDGE and FIXME: not sorting Master; manually sorting Master in json file
  ) {
    meta.sort((a, b) => {
      const aName = a.name.toUpperCase()
      const bName = b.name.toUpperCase()
      return aName.localeCompare(bName)
    })
  }
  meta = sortDecks(meta, activeSortDecks)
  meta.date = date
  return meta
}

export default sortMeta
