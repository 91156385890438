import { useTransition, useEffect, useRef } from 'react'
/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react'
import ButtonStyles from './ButtonStyles'
import { ButtonProps } from '../../../types/types'
import Loading from '../../Loading'

const Button = ({
  text,
  className,
  onClickAction,
  nextDeck,
  sort,
}: ButtonProps) => {
  const [isPending, startTransition] = useTransition()

  const theme = useTheme()
  const classes = ButtonStyles(theme)

  const buttonRef = useRef<any>() // FIXME: any

  useEffect(() => {
    const doScroll = () => {
      if (
        className?.includes('selected') &&
        !className.includes('class-header')
      ) {
        // block: 'start' causes first menu item to move a few pixels above start of menu
        buttonRef.current.scrollIntoView({ block: 'nearest' })
      }
    }
    setTimeout(doScroll, 1) // KLUDGE: setTimeout required; tick allows time for ref to render?
  }) // No dependency array, should run on every render

  return (
    <>
      {isPending ? <Loading /> : null}
      <button
        type="button"
        css={classes.button}
        className={className}
        onClick={
          onClickAction ? () => startTransition(onClickAction) : undefined
        }
        ref={buttonRef}
      >
        <div css={classes.buttonNameDate}>
          <div>{text}</div>
          {sort !== 'Streamer' &&
            nextDeck?.concurrence &&
            nextDeck.concurrence.length > 0 && (
              <div css={classes.concurrence}>
                + {nextDeck.concurrence.length.toString()}
              </div>
            )}
          {sort !== 'Streamer' && nextDeck?.date && (
            <div css={classes.date}>{nextDeck.date}</div>
          )}
        </div>
      </button>
    </>
  )
}

export default Button
