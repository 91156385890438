import { CSSObject, Theme, keyframes } from '@emotion/react'

const SettingsStyles = (theme: Theme): CSSObject => {
  // FIXME: KLUDGE to limit font fout
  const fadeIn = keyframes({
    to: { opacity: 1 },
  })
  return {
    containerSettings: {
      position: 'relative',
      display: 'none',
      color: theme.fontColor,
    },

    containerSettingsOpen: {
      position: 'absolute',
      top: 0,
      left: 0,
      display: 'flex',
      justifyContent: 'center',
      // alignItems: 'center',
      // height: '100%',
      width: '100%',
      margin: 0,
    },

    settingsCloser: {
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 5,
    },

    settingsCloserOpen: {
      display: 'block',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 5,
      height: '100%',
      width: '100%',
      transition: 'background-color .2s ease-out',
    },

    settingsModal: {
      label: 'settings-modal',
      position: 'relative',
      top: 40,
      zIndex: 7,
      width: 'auto',
      minWidth: 300,
      padding: `${theme.remFull} ${theme.remFull} ${theme.remHalf} ${theme.remFull}`,
      border: theme.border,
      backgroundColor: theme.backgroundColor,
      boxShadow: '0 0 20px rgba(0, 0, 0, .3)',
      '@media only screen and (min-width: 768px)': {
        top: 80,
      },
      '@media only screen and (min-width: 992px)': {
        top: 100,
      },
    },

    modalHeader: {
      fontFamily: theme.fontStackTitle,
      fontSize: theme.fontSubheadSize,
      color: theme.fontColor,
      // fontWeight: theme.fontBodyWeightBold,
      margin: `0 0 ${theme.remHalf} 0`,
      padding: `0 0 0 .4rem`,
      // '@media only screen and (min-width: 768px)': {
      //   fontFamily: theme.fontStackTitle,
      //   fontSize: theme.fontSubheadSize,
      // },
      '@media only screen and (min-width: 992px)': {
        fontSize: theme.fontDesktopSubheadSize,
      },
    },

    buttonSettingsOpen: {
      label: 'button-settings-open',
      position: 'absolute',
      zIndex: 5,
      border: 0,
      right: 54,
      top: 9,
      display: 'flex',
      cursor: 'pointer',
      backgroundColor: theme.backgroundColorHeader,
      transform: 'translateY(0)',
      transition: 'transform 0.2s ease',
      '&.hidden': {
        transform: 'translateY(-200%)',
      },
      '&:hover img': {
        filter: theme.filterLink,
      },
      '@media only screen and (min-width: 768px)': {
        // KLUDGE to limit font fout
        opacity: 0,
        animation: `0s linear 0.75s forwards ${fadeIn}`,
        right: 5,
        top: 16,
        padding: `${theme.remQuarter} 0 0 ${theme.remHalf}`,
        transform: 'none',
        transition: 'unset',
        '&.hidden': {
          transform: 'none',
        },
      },
      '@media only screen and (min-width: 992px)': {
        right: 10,
        top: 30,
      },
    },

    buttonSettingsOpenHeader: {
      fontSize: theme.fontSubheadSize,
      position: 'absolute',
      right: 9999,
      visibility: 'hidden',
      color: theme.fontColor,
      '&:hover': {
        color: theme.fontColorLink,
      },
      '@media only screen and (min-width: 768px)': {
        right: 22,
        padding: 0,
        margin: 0,
        visibility: 'visible',
      },
      '@media only screen and (min-width: 992px)': {
        fontSize: theme.fontDesktopSubheadSize,
        padding: `0 ${theme.remQuarter} 0 0`,
      },
      // '&.newUser': {
      //   '@media only screen and (min-width: 768px)': {
      //     // whiteSpace: 'nowrap',
      //     // top: 0,
      //     color: theme.fontColorLink,
      //     // backgroundColor: theme.backgroundColorHover,
      //     // padding: `${theme.remEighth} ${theme.remHalf}`,
      //     // border: theme.border,
      //   },
      // '@media only screen and (min-width: 992px)': {
      //   marginRight: theme.remHalf,
      // },
      // }
    },

    buttonSettingsOpenImg: {
      border: 0,
      height: 24,
      // set svg to font color
      filter: theme.filter,
      '@media only screen and (min-width: 768px)': {
        padding: '1px 0 0 0',
        height: 20,
      },
      '@media only screen and (min-width: 992px)': {
        padding: '2px 0 0 0',
        height: 23,
      },
    },

    buttonSettingsClose: {
      position: 'absolute',
      top: 14,
      right: 14,
      cursor: 'pointer',
    },

    buttonSettingsCloseImg: {
      height: 24,
      filter: theme.filter,
    },

    settingsGroup: {
      margin: `0 0 ${theme.remFull} 0`,
      // opacity: 1,
      '&.disabled': {
        opacity: 0.25,
      },
    },

    settingsGroupHeading: {
      color: theme.fontColor,
      fontWeight: theme.fontBodyWeightBold,
      fontSize: theme.fontButtonSize,
      // fontWeight: theme.fontBodyWeightBold,
      margin: `${theme.remQuarter} 0 ${theme.remEighth} 0`,
      padding: `${theme.remHalf} ${theme.remHalf} 0`,
      '@media only screen and (min-width: 992px)': {
        fontSize: theme.fontDesktopButtonSize,
      },
    },

    settingsSubGroup: {
      padding: `${theme.remQuarter} 0 0 ${theme.remHalf}`,
      display: 'flex',
      alignItems: 'center',
    },

    settingsSubGroupHeading: {
      fontSize: theme.fontButtonSize,
      '@media only screen and (min-width: 992px)': {
        fontSize: theme.fontDesktopButtonSize,
      },
    },

    settingsInput: {
      label: 'settings-input',
      width: '100%',
      display: 'flex',
      flexFlow: 'row nowrap',
      margin: 0,
      padding: theme.remQuarter,
    },

    /**
     * Deck View Radio Buttons
     */
    radioLabel: {
      label: 'radio-label',
      padding: `6px ${theme.remThreefourth} ${theme.remQuarter} 25px`,
      display: 'block',
      position: 'relative',
      cursor: 'pointer',
      '@media only screen and (min-width: 992px)': {
        fontSize: theme.fontDesktopButtonSize,
        padding: `${theme.remQuarter} ${theme.remThreefourth} ${theme.remQuarter} 1.75rem`,
      },
    },

    // Hide browser default
    radio: {
      label: 'radio',
      position: 'absolute',
      opacity: 0,
      cursor: 'pointer',
      '&:checked ~ .checkmark': {
        backgroundColor: 'black',
      },
      '&:checked ~ .checkmark:after': {
        display: 'block',
      },
    },

    // Create custom radio button
    checkmark: {
      label: 'checkmark',
      position: 'absolute',
      top: 4,
      left: 0,
      height: 18,
      width: 18,
      margin: `0 0 0 ${theme.remQuarter}`,
      backgroundColor: theme.backgroundColorHeader,
      borderRadius: '50%',
      '&:after': {
        content: '""', // double quotes required
        position: 'absolute',
        display: 'none',
        top: 5,
        left: 5,
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: theme.backgroundColorLight,
      },
    },

    /**
     * Card Size Slider
     */
    cardsizeRange: {
      width: '100%', // Full-width
      // Chrome
      margin: `11px 0 0 ${theme.remQuarter}`,
      appearance: 'none',
      outline: 'none',
      height: 2,
      backgroundColor: theme.fontColor,
      '&::-webkit-slider-runnable-track': {
        height: 18,
        appearance: 'none',
      },
      '&::-webkit-slider-thumb': {
        width: 18,
        height: 18,
        appearance: 'none',
        borderRadius: 10,
        cursor: 'pointer',
        border: 0,
        background: theme.fontColor,
      },
    },

    cardsizeReset: {
      fontFamily: theme.fontStackBody,
      fontSize: theme.fontButtonSize,
      color: theme.fontColor,
      margin: `0 ${theme.remHalf} 0 ${theme.remFull}`,
      padding: `${theme.remQuarter} ${theme.remHalf}`,
      width: 'auto',
      border: theme.border,
      backgroundColor: theme.backgroundColor,
      cursor: 'pointer',
      outline: 0,
      '@media only screen and (min-width: 992px)': {
        fontSize: theme.fontDesktopButtonSize,
      },
    },

    /**
     * Theme Checkbox (Switch)
     */
    // The switch - the box around the slider
    // labelSwitch: {
    //   label: 'label-switch',
    //   position: 'relative',
    //   display: 'inline-block',
    //   width: 42,
    //   height: 20,
    //   margin: `0 ${theme.remHalf}`,
    //   border: `2px solid ${theme.fontColor}`,
    //   '@media only screen and (min-width: 992px)': {
    //     margin: `0 ${theme.remThreefourth}`,
    //   },
    // },

    // // Hide default HTML checkbox
    // inputSwitch: {
    //   label: 'input-switch',
    //   opacity: 0,
    //   width: 0,
    //   height: 0,
    //   '&:checked ~ .slider': {
    //     backgroundColor: theme.backgroundColorLight, // checked/after background color
    //   },
    //   '&:checked ~ .slider:before': {
    //     transform: 'translateX(21px)',
    //     // backgroundColor: '#f0ebdf', // checked/after switch
    //     backgroundColor: theme.fontColor, // checked/after switch
    //   },
    // },

    // // The slider
    // slider: {
    //   label: 'slider',
    //   position: 'absolute',
    //   cursor: 'pointer',
    //   top: 0,
    //   left: 0,
    //   right: 0,
    //   bottom: 0,
    //   backgroundColor: theme.backgroundColorHeader, // unchecked/before background
    //   transition: '.4s',
    //   '&:before': {
    //     position: 'absolute',
    //     content: '""', // double quotes required
    //     height: 12,
    //     width: 12,
    //     left: 4,
    //     bottom: 4,
    //     backgroundColor: theme.fontColor, // unchecked/before switch
    //     transition: '.4s',
    //   },
    // },
  }
}

export default SettingsStyles
