import { CSSObject, Theme, keyframes } from '@emotion/react'

const LoadingStyles = (theme: Theme): CSSObject => {
  // animation prevents Loading from flashing on screen when changing decks
  const fadeIn = keyframes({
    to: { opacity: 0.75 },
  })
  const animFw = keyframes({
    '0%': {
      width: 0,
    },
    '100%': {
      width: '100%',
    },
  })
  return {
    loading: {
      position: 'fixed',
      zIndex: 10, // should be 1 below Header
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      padding: 0,
      margin: 0,
      backgroundColor: `${theme.backgroundColor}`,
      opacity: 0,
      animation: `0s linear 1s forwards ${fadeIn}`,
      '&.app': {
        backgroundColor: '#dcd7cc',
      },
    },
    containerLogo: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      filter: theme.filter,
      '@media only screen and (min-width: 768px)': {
        padding: '0 0 0 190px', // add width of sidebar, to center deck
      },
      '@media only screen and (min-width: 992px)': {
        padding: '0 0 0 220px', // add width of sidebar, to center deck
      },
      '&.app': {
        padding: 0,
      },
    },
    logoImg: {
      height: 100,
      width: 100,
      padding: 0,
      margin: 0,
      opacity: 0.65,
      '@media only screen and (min-width: 768px)': {
        height: 200,
        width: 200,
      },
      '@media only screen and (min-width: 992px)': {
        height: 200,
        width: 200,
      },
    },
    barLoader: {
      label: 'bar-loader',
      width: 'auto',
      margin: '0 10% 0 10%',
      height: 4.8,
      opacity: 0.65,
      /* display: inline-block; */
      position: 'relative',
      background: 'rgb(0, 0, 0, 0.2)',
      overflow: 'hidden',
      '&:after': {
        content: '""', // double quotes required,
        boxSizing: 'border-box',
        width: 0,
        height: 4.8,
        background: 'rgb(0, 0, 0)',
        position: 'absolute',
        top: 0,
        left: 0,
        animation: `${animFw} 6s linear infinite`,
      },
    },
  }
}

export default LoadingStyles
