import removeWordBoundaries from './removeWordBoundaries'
import { Meta, MetaDeck, MetaPlayer, FindDeckResults } from '../types/types'

const findDeck = (
  { queryArchetype, queryName }: { queryArchetype: string; queryName: string },
  metaArchetypes: Meta,
  ...metasPlayer: Meta[]
): FindDeckResults => {
  let results: FindDeckResults = {
    deck: null,
    sort: null,
    meta: null,
  }
  // FIXME: Why did variable 'deck' (instead of 'newDeck') cause meta property to be added to 'deck' object?
  let newDeck = null
  let foundArchetype
  let foundArchetypeDeck
  let foundPlayerDeck

  // Find Archetype
  if (queryArchetype) {
    foundArchetype = metaArchetypes.find(
      (archetype) =>
        removeWordBoundaries(archetype.name).toUpperCase() ===
        removeWordBoundaries(queryArchetype).toUpperCase()
    )
    // Archetype Deck
    if (foundArchetype) {
      if (queryName) {
        const archetypeDeckRegExp = new RegExp(
          removeWordBoundaries(queryName),
          'i'
        )
        foundArchetypeDeck = foundArchetype.decks.find(
          (deck: MetaDeck) =>
            removeWordBoundaries(deck.src).toUpperCase() ===
              removeWordBoundaries(queryName).toUpperCase() ||
            archetypeDeckRegExp.test(removeWordBoundaries(deck.src))
        )
      }
      if (foundArchetypeDeck) {
        newDeck = {
          name: foundArchetypeDeck.name,
          deckcode: foundArchetypeDeck.deckcode,
          src: foundArchetypeDeck.src,
          url: foundArchetypeDeck.url,
          archetype: foundArchetype.name,
          archClass: foundArchetype.archClass,
          concurrence: foundArchetypeDeck.concurrence,
          cache: foundArchetypeDeck.cache,
        }
      } else {
        newDeck = {
          name: foundArchetype.name,
          deckcode: foundArchetype.deckcode,
          src: undefined,
          url: undefined,
          archetype: foundArchetype.name,
          archClass: foundArchetype.archClass,
          concurrence: undefined,
          cache: foundArchetype.cache,
        }
      }

      // passing 'sort: null' for non-player results,
      // means current sort will be used
      results = {
        deck: newDeck,
        sort: null,
        meta: metaArchetypes,
      }
    }
  }
  // Find metasPlayer
  if (!foundArchetype && queryArchetype) {
    const activePlayerJsons = metasPlayer.filter((json) => json)
    for (const json of activePlayerJsons) {
      let urlStreamer = queryArchetype
      // try matching exact name first; players can have similar names
      // then try matching part of name
      const playerRegExp = new RegExp(removeWordBoundaries(urlStreamer), 'i')
      let foundPlayer = json.find(
        (player: MetaPlayer) =>
          // replace underscores and ignore case to be more forgiving with typos
          player.name.replace('_', '').toUpperCase() ===
            urlStreamer.replace('_', '').toUpperCase() ||
          playerRegExp.test(removeWordBoundaries(player.name))
      )
      // Streamer Deck
      if (foundPlayer) {
        if (queryName) {
          const playerDeckRegExp = new RegExp(queryName, 'i')
          foundPlayerDeck = foundPlayer.decks.find(
            (deck: MetaDeck) =>
              removeWordBoundaries(deck.name).toUpperCase() ===
                removeWordBoundaries(queryName).toUpperCase() ||
              playerDeckRegExp.test(removeWordBoundaries(deck.name))
          )
        }
        if (!foundPlayerDeck) foundPlayerDeck = foundPlayer.decks[0]
        newDeck = {
          name: foundPlayerDeck.name,
          deckcode: foundPlayerDeck.deckcode,
          src: foundPlayerDeck.src,
          url: foundPlayerDeck.url,
          archetype: foundPlayer.name,
          archClass: foundPlayer.archClass,
          concurrence: foundPlayerDeck.concurrence,
          cache: foundPlayerDeck.cache,
        }
        const playerSort =
          foundPlayer.archClass === 'Master' ? 'Master' : 'Streamer'
        results = {
          deck: { ...newDeck },
          sort: playerSort,
          meta: json,
        }
        break
      } else {
        urlStreamer = urlStreamer.replace(/(%20|\+|-|_|&)/g, ' ')
        newDeck = {
          name: `${urlStreamer}`,
        }
        results = { deck: { ...newDeck }, sort: 'Streamer', meta: null }
      }
    }
  }
  return results
}

export default findDeck
