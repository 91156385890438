import removeWordBoundaries from './removeWordBoundaries'
import { Deck, Sort } from '../types/types'

const makeNewURL = (activeDeck: Deck, sort: Sort) => {
  let newURL
  // no src or deckcode means no result findDeck from url search
  if (!activeDeck.src && !activeDeck.deckcode) {
    newURL = '/' + activeDeck.name
    // archetypes don't have .src
  } else if (!activeDeck.src) {
    newURL = '/' + activeDeck.archetype
  } else if (sort === 'Streamer' || sort === 'Master') {
    newURL = '/' + activeDeck.archetype + '/' + activeDeck.name
  } else {
    newURL = '/' + activeDeck.archetype + '/' + activeDeck.src
  }
  newURL = removeWordBoundaries(newURL)
  return newURL
}

export default makeNewURL
