/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react'

import HeaderStyles from './HeaderStyles'

import logoDesktop from './images/header-logo-desktop.png'
import logoDesktopSmall from './images/header-logo-desktop-small.png'
import logoMobile from './images/header-logo-mobile.png'

const Header = ({ windowWidth }: { windowWidth: number }) => {
  const theme = useTheme()
  const classes = HeaderStyles(theme)

  const imageSrc = () => {
    if (windowWidth > 992) return logoDesktop
    if (windowWidth > 768) return logoDesktopSmall
    return logoMobile
  }

  if (windowWidth && imageSrc) {
    return (
      <div
        id="header"
        css={classes.header}
        // className={props.headerVisible ? null : 'hidden'}
      >
        <div css={classes.headerLogo}>
          <a href="https://fullmetajackal.com">
            <img
              css={classes.headerLogoImg}
              alt="Full Meta Jackal"
              src={imageSrc()}
            />
          </a>
        </div>
      </div>
    )
  } else {
    return <div css={classes.header}></div>
  }
}

export default Header
