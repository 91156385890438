import './typekit.css' // FIXME: Should this be first?
import {
  StrictMode,
  useEffect,
  useState,
  Suspense,
  MouseEvent,
  ChangeEvent,
} from 'react'
/** @jsxImportSource @emotion/react */
import { ThemeProvider } from '@emotion/react'

import debounce from 'lodash/debounce'

import Header from './components/Header'
import Settings from './components/Settings'
import Provider from './Provider'
import { ThemeFlavor, CardSize, DeckView, SortDecks } from './types/types'

import theme from './theme'

// Firebase
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
const firebaseConfig = {
  apiKey: 'AIzaSyDYMo8FUiNG0EZufEdHy-Qes-wJ9kTaGU0',
  authDomain: 'full-meta-jackal.firebaseapp.com',
  // databaseURL: "https://full-meta-jackal.firebaseio.com",
  projectId: 'full-meta-jackal',
  // storageBucket: "full-meta-jackal.appspot.com",
  // messagingSenderId: "737123808671",
  appId: '1:737123808671:web:1340e7c7b8d7857d5539aa',
  measurementId: 'G-S3FDK8MN40',
}
const app = initializeApp(firebaseConfig)
getAnalytics(app)

const App = () => {
  /**
   * Theme
   */
  const [themeFlavor, setThemeFlavor] = useState<ThemeFlavor>('dark')

  const setLocalThemeFlavor = (flavor: ThemeFlavor) => {
    if (typeof Storage !== 'undefined')
      localStorage.setItem('themeFlavor', flavor)
  }

  const handleSetTheme = (flavor: ThemeFlavor) => {
    setThemeFlavor(flavor)
    setLocalThemeFlavor(flavor)
  }

  useEffect(() => {
    const localFlavor = localStorage.themeFlavor
      ? localStorage.getItem('themeFlavor')
      : null
    const flavor: ThemeFlavor = localFlavor !== 'light' ? 'dark' : 'light'
    setThemeFlavor(flavor)
  }, [])

  /**
   * Card Size
   */
  const [cardSize, setCardSize] = useState(5) // range 1 to 10, default 5

  useEffect(() => {
    const getLocalCardSize = () => {
      // check for storage
      if (localStorage.cardSize) {
        return Number(localStorage.getItem('cardSize'))
      } else {
        return 5
      }
    }
    const localCardSize: CardSize = getLocalCardSize()
    setCardSize(localCardSize)
  }, [])

  const setLocalCardSize = () => {
    // check for storage
    if (typeof Storage !== 'undefined') {
      localStorage.setItem('cardSize', cardSize.toString())
    }
  }

  const changeCardSize = (size: CardSize) => {
    setCardSize(size)
  }

  const handleCardSizeChange = (e: MouseEvent | ChangeEvent | 'reset') => {
    let size: CardSize
    if (e === 'reset') size = 5
    else size = Number((e.target as HTMLInputElement).value)
    changeCardSize(size)
  }

  /**
   * Footer
   *
   * Footer needs to be hidden when adjusting card size. This looks better
   * and suppresses Chrome performance cumulative layout shift (CLS) warnings.
   * Set initial value to false. Footer is set to true and loads after ViewDeck_.
   */
  const [footerVisible, setFooterVisible] = useState(false)

  /**
   * Header
   *
   * Hide header when scrolling on mobile
   */
  // const [headerVisible, setHeaderVisible] = useState(true)

  /**
   * Window size
   */
  // FIXME: Why am I doing this here? Why not in components?
  const [windowWidth, setWindowWidth] = useState(0)

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth)
    }
    const debouncedHandleResize = debounce(handleResize, 100)
    window.addEventListener('resize', debouncedHandleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  /**
   * View version: tiles or cards
   */
  const [deckView, setDeckView] = useState<DeckView>('default')
  useEffect(() => {
    const getLocalDeckView = () => {
      // check for storage
      if (localStorage.deckView) {
        return localStorage.getItem('deckView') as DeckView
      }
      return 'default'
    }
    const localDeckView = getLocalDeckView()
    setDeckView(localDeckView)
  }, [])

  const handleSetDeckView = (view: DeckView) => {
    // suppress Footer flashing when changing
    // from a card view to a tile view
    if (
      !(deckView === 'default' || deckView === 'cards') &&
      (view === 'default' || view === 'cards')
    )
      setFooterVisible(false)
    setDeckView(view)
    if (typeof Storage !== 'undefined') {
      localStorage.setItem('deckView', view)
    }
  }

  /**
   * Sort for decks: date or concurrence
   */
  const [sortDecks, setSortDecks] = useState<SortDecks>('date')

  useEffect(() => {
    const getLocalSortDecks = (): SortDecks => {
      // check for storage
      if (localStorage.sortDecks) {
        const localSortDecks = localStorage.getItem('sortDecks')
        if (localSortDecks === 'date' || localSortDecks === 'concurrence')
          return localSortDecks
      }
      return 'date'
    }
    const localSortDecks = getLocalSortDecks()
    setSortDecks(localSortDecks)
  }, [])

  const handleSortDecksChange = (sortDecks: SortDecks) => {
    setSortDecks(sortDecks)
    if (typeof Storage !== 'undefined') {
      localStorage.setItem('sortDecks', sortDecks)
    }
  }

  return (
    <ThemeProvider theme={theme[themeFlavor]}>
      <div id="container">
        <StrictMode>
          <Suspense fallback={null}>
            <Settings
              cardSize={cardSize}
              handleCardSizeChange={handleCardSizeChange}
              setLocalCardSize={setLocalCardSize}
              handleSetTheme={handleSetTheme}
              themeFlavor={themeFlavor}
              setFooterVisible={setFooterVisible}
              deckView={deckView}
              handleSetDeckView={handleSetDeckView}
              sortDecks={sortDecks}
              handleSortDecksChange={handleSortDecksChange}
              windowWidth={windowWidth}
            />
          </Suspense>
          <Header
            // headerVisible={headerVisible}
            windowWidth={windowWidth}
          />
          {
            // FIXME: provide meaningful fallback
          }
          <Suspense fallback={null}>
            <Provider
              themeFlavor={themeFlavor}
              // headerVisible={headerVisible}
              // setHeaderVisible={setHeaderVisible}
              footerVisible={footerVisible}
              cardSize={cardSize}
              deckView={deckView}
              sortDecks={sortDecks}
              windowWidth={windowWidth}
              setFooterVisible={setFooterVisible}
            />
          </Suspense>
        </StrictMode>
      </div>
    </ThemeProvider>
  )
}

export default App
