/** @jsxImportSource @emotion/react */
import { useEffect, useRef, ChangeEvent } from 'react'
import { useTheme } from '@emotion/react'
import debounce from 'lodash/debounce'
import closeIcon from '../images/close-icon.svg'

import { CSSObject, Theme } from '@emotion/react'

interface SearchProps {
  searchText: string
  handleSearch: (e: ChangeEvent) => void
}

const FilterStyles = (theme: Theme): CSSObject => {
  return {
    search: {
      position: 'relative',
      color: theme.fontColor,
      margin: `${theme.remQuarter} 0 0 ${theme.remHalf}`,
      padding: `${theme.remQuarter} 0 ${theme.remEighth} 0`,
      '@media only screen and (min-width: 992px)': {
        margin: `${theme.remEighth} 0 0 ${theme.remQuarter}`,
        padding: `${theme.remHalf} 0 ${theme.remQuarter} ${theme.remQuarter}`,
        fontSize: theme.fontDesktopButtonSize,
      },
    },
    // labelCard: {
    //   // position: 'absolute',
    //   // padding: `.2rem 0 0 ${theme.remEighth}`,
    //   '@media only screen and (min-width: 992px)': {
    //     // padding: `.125rem 0 0 ${theme.remQuarter}`,
    //     fontSize: theme.fontDesktopButtonSize,
    //   },
    // },
    inputSearch: {
      // WebkitAppearance: 'none',
      // WebkitBoxShadow: 'none',
      border: theme.border,
      // boxShadow: 'none',
      outline: 'none',
      color: theme.fontColor,
      backgroundColor: theme.backgroundColorHighlight,
      padding: `${theme.remEighth} 0 ${theme.remEighth} ${theme.remHalf}`,
      margin: 0,
      width: '89%',
      '::placeholder': {
        color: theme.fontColor,
        opacity: 1,
      },
      ':focus::placeholder': {
        color: 'transparent',
      },
      '@media only screen and (min-width: 992px)': {
        padding: `${theme.remEighth} 0 ${theme.remEighth} ${theme.remTwothird}`,
      },
    },
    inputClear: {
      position: 'absolute',
      left: '9.6rem',
      top: '.4rem',
      cursor: 'pointer',
      // backgroundColor: theme.backgroundColorDark,
      // border: theme.border,
      '@media only screen and (min-width: 992px)': {
        left: '11.25rem',
        top: '.6rem',
      },
    },
    closeImg: {
      filter: theme.filter,
      height: '1rem',
      '@media only screen and (min-width: 992px)': {
        height: '1.25rem',
      },
    },
  }
}

const Search = (props: SearchProps) => {
  const debounceHandleSearch = debounce((e) => props.handleSearch(e), 500)

  useEffect(() => {
    return () => {
      debounceHandleSearch.cancel()
    }
  })

  const inputSearchRef = useRef<HTMLInputElement>(null)

  const clearInput = () => {
    if (inputSearchRef && inputSearchRef.current)
      inputSearchRef.current.value = ''
    const e = { target: { value: '' } }
    debounceHandleSearch(e)
  }

  const theme = useTheme()
  const classes = FilterStyles(theme)

  return (
    <div css={classes.search}>
      <input
        css={classes.inputSearch}
        type="text"
        id="search"
        placeholder="Search by card, streamer, arch..."
        ref={inputSearchRef}
        onChange={(e) => debounceHandleSearch(e)}
      />
      {props.searchText && (
        <div css={classes.inputClear} onClick={clearInput}>
          <img css={classes.closeImg} src={closeIcon} alt="Close" />
        </div>
      )}
    </div>
  )
}

export default Search
