/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react'
import logo from './images/logo.png'
import LoadingStyles from './LoadingStyles'

const Loading = () => {
  const theme = useTheme()
  const classes = LoadingStyles(theme)

  return (
    <div css={classes.loading}>
      <div css={classes.containerLogo}>
        <img
          css={classes.logoImg}
          alt="Full Meta Jackal is loading..."
          src={logo}
          width="200"
          height="200"
        />
        <div css={classes.barLoader}></div>
      </div>
    </div>
  )
}

export default Loading
