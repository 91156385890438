/** @jsxImportSource @emotion/react */
import { DeckHeaderProps } from '../../types/types'
import { useTheme, CSSObject, Theme /*, keyframes */ } from '@emotion/react'

import DeckSubHeader from './DeckSubHeader'

const DeckHeaderStyles = (theme: Theme): CSSObject => {
  // const fadeIn = keyframes({
  //   to: { opacity: 1 },
  // })
  return {
    deckheaderContainer: {
      margin: `0 0 ${theme.remQuarter} 0`,
      opacity: 1,
      // animation: `0s linear 0.75s forwards ${fadeIn}`,
      '& a': {
        textDecoration: 'none',
        color: theme.fontColorLink,
        '&:link': {
          color: theme.fontColorLink,
        },
        '&:hover': {
          textDecoration: 'none',
          color: theme.fontColorLink,
          // borderBottom: theme.borderLink,
        },
        '&:visited': {
          color: theme.fontColorLink,
        },
        '&:active': {
          color: theme.fontColorSelected,
        },
      },
    },
    deckName: {
      fontSize: '1.2rem',
      textAlign: 'center',
      margin: 0,
      padding: `${theme.remHalf} ${theme.remHalf} 0 ${theme.remHalf}`,
      color: theme.fontColor,
      '@media only screen and (min-width: 768px)': {
        fontSize: '1.75rem',
      },
      '@media only screen and (min-width: 992px)': {
        fontSize: '2rem',
        padding: `${theme.remThreefourth} 0 0 0`,
      },
    },
    // deckMoreInfo: {
    //   label: 'more-info',
    //   textAlign: 'center',
    //   margin: 0,
    //   padding: 0,
    //   '@media only screen and (min-width: 768px)': {
    //     padding: `${theme.remEighth} 0 0 0`,
    //     fontSize: '1rem',
    //   },
    //   '@media only screen and (min-width: 992px)': {
    //     fontSize: '1.25rem',
    //     padding: `${theme.remEighth} 0 0 0`, // add width of sidebar, to center deck
    //   },
    // },
  }
}

const DeckHeader = (props: DeckHeaderProps) => {
  // jsx rendered by DeckHeader
  // no .src means deck not found from findDeck url search
  const displayNameDeck = !props.activeDeck.src ? (
    props.activeDeck.name
  ) : props.sort !== 'Streamer' ? (
    <>
      <a href={props.activeDeck.url}>{props.activeDeck.src}</a>
      {' ' + props.activeDeck.archetype}
    </>
  ) : (
    <>
      <a href={props.activeDeck.url}>{props.activeDeck.src}</a>
      {' ' + props.activeDeck.name}
    </>
  )

  const theme = useTheme()
  const classes = DeckHeaderStyles(theme)

  return (
    <div css={classes.deckheaderContainer}>
      <h1 css={classes.deckName}>
        {props.loading ? <span>Loading </span> : null}
        {displayNameDeck}
        {props.loading ? <span> ...</span> : null}
      </h1>
      {props.activeDeck.cache ? (
        <DeckSubHeader activeDeck={props.activeDeck} />
      ) : null}
      {/* {props.activeDeck.src && (
        <div css={classes.deckMoreInfo}>
          <a
            href={props.activeDeck.url}
            target="_blank"
            rel="noreferrer noopener"
          >
            {props.activeDeck.src}
          </a>
        </div>
      )} */}
    </div>
  )
}

export default DeckHeader
