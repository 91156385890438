import { Theme } from '@emotion/react'

const ContainerStyles = (theme: Theme) => {
  return {
    // "@import": "url(https://use.typekit.net/dgb6ahc.css)",
    'html, body': {
      height: '100%',
      width: '100%',
      margin: 0,
      padding: 0,
      /* Safari only override */
      // overflow: "hidden" prevents iOS scroll bounce from covering elements
      // but setting overflow: "hidden" for android mobile keeps address bar
      // from auto hiding on scroll
      '@supports (background: -webkit-named-image(i))': {
        overflow: 'hidden',
      },
    },
    input: {
      fontFamily: theme.fontStackBody,
      fontWeight: theme.fontBodyWeight,
      fontSize: theme.fontButtonSize,
      color: theme.fontColor,
      fontSmoothing: 'antialiased',
      padding: 0,
      margin: 0,
      '@media only screen and (min-width: 992px)': {
        fontSize: theme.fontDesktopButtonSize,
      },
    },
    '#root': {
      top: 0,
      left: 0,
      width: '100%', // 100% width and height allow scrolling
      height: '100%',
      backgroundColor: theme.backgroundColor,

      fontFamily: theme.fontStackBody,
      fontWeight: theme.fontBodyWeight,
      fontSize: theme.fontButtonSize,
      color: theme.fontColor,
      fontSmoothing: 'antialiased',
    },
    '#container': {
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      padding: 0,
      margin: 0,
      color: theme.fontColor,
      backgroundColor: theme.backgroundColorDeck, // for Deck Suspense transition
    },
    topFallback: {
      backgroundColor: theme.backgroundColor,
    },
    'h1, h2': {
      fontFamily: theme.fontStackTitle,
      fontSize: theme.fontTitleSize,
      fontWeight: theme.fontTitleWeight,
      '@media only screen and (min-width: 992px)': {
        fontSize: theme.fontDesktopTitleSize,
      },
    },
    'h3, h4, h5, h6': {
      fontFamily: theme.fontStackTitle,
      fontSize: theme.fontSubheadSize,
      fontWeight: theme.fontTitleWeight,
    },
    h3: {
      '@media only screen and (min-width: 992px)': {
        fontSize: theme.fontDesktopSubheadSize,
      },
    },
    'h4, h5, h6': {
      fontSize: theme.remFull,
      '@media only screen and (min-width: 992px)': {
        fontSize: '1.25rem',
      },
    },
    hr: {
      border: 0,
      borderBottom: theme.border,
      margin: 0,
      marginTop: theme.remHalf,
      padding: 0,
    },
    'hr.solid': {
      border: 0,
      borderBottom: theme.border,
      margin: `${theme.remHalf} -9999rem 0 -9999rem`,
      padding: '0 9999rem',
      '@media only screen and (min-width: 992px)': {
        marginTop: theme.remThreefourth,
      },
    },
    'hr.shadow': {
      height: 10,
      marginTop: 0,
      marginBottom: 10,
      border: 0,
      borderBottom: theme.border,
      boxShadow: '0 3px 3px -3px rgba(0, 0, 0, .3)',
    },
  }
}

export default ContainerStyles
