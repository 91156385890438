import { keyframes, CSSObject, Theme } from '@emotion/react'

const CopyDeckcodeStyles = (theme: Theme): CSSObject => {
  const fadeIn = keyframes({
    to: { opacity: 1 },
  })
  return {
    deckcodeContainer: {
      zIndex: 2,
      position: 'fixed',
      top: 140,
      right: '-28px',
      margin: 0,
      transform: 'rotate(270deg)',
      overflow: 'hidden', // hide deckcodeTextarea
      // FIXME: boxShadow not working
      boxShadow: '-2px 1px 3px 3px rgba(0, 0, 0, .15)',
      opacity: 0,
      animation: `0s linear 0.75s forwards ${fadeIn}`,
      '@media only screen and (min-width: 768px)': {
        zIndex: 3,
        position: 'fixed',
        top: 62,
        right: '-4px',
        transform: 'none',
      },
      '@media only screen and (min-width: 992px)': {
        top: 82,
      },
    },
    deckcodeTextarea: {
      position: 'absolute', // can't set display: none for copy functionality
      left: '-9999px',
    },
    deckcodeButton: {
      outline: 0,
      cursor: 'pointer',
      fontFamily: theme.fontStackBody,
      color: theme.fontColor,
      margin: 0,
      padding: `${theme.remQuarter} 1.5rem ${theme.remThreefourth} ${theme.remHalf}`,
      border: theme.border,
      backgroundColor: theme.backgroundColorHeader,
      width: 92,
      textAlign: 'left',
      // '&.selected': {
      // color: theme.fontColor,
      // backgroundColor: theme.backgroundColor,
      // },
      // '&:link': {
      // color: theme.fontColor,
      // backgroundColor: theme.backgroundColor,
      // },
      // '&:visited': {
      //   color: theme.fontColor,
      // backgroundColor: theme.backgroundColor,
      // },
      '&:hover': {
        color: theme.fontColorHover,
        // backgroundColor: theme.backgroundColorHover,
      },
      '&:hover img': {
        filter: theme.filterLink,
      },
      // '&:active': {
      //   backgroundColor: theme.backgroundColorHeader,
      // },
      '@media only screen and (min-width: 768px)': {
        padding: `.4rem 1.75rem .4rem ${theme.remTwothird}`,
        // boxShadow: '0 0 10px rgba(0, 0, 0, .2)',
      },
      '@media only screen and (min-width: 992px)': {
        width: 94,
        fontSize: theme.fontDesktopButtonSize,
        padding: `${theme.remHalf} 0 ${theme.remHalf} ${theme.remTwothird}`,
      },
    },
    deckcodeButtonImg: {
      position: 'absolute',
      right: 10,
      margin: 0,
      // padding: `0 4px 0 ${theme.remQuarter}`,
      height: 16,
      filter: `${theme.filter}`,
    },
  }
}

export default CopyDeckcodeStyles
