import { useState } from 'react'
/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react'
import Filter from './Filter'
import Search from './Search'
import Sorter from './Sorter'
import Menu from './menu/Menu'
import menuIcon from '../images/menu-icon.svg'
import menuOpenIcon from '../images/menu-open-icon.svg'
import SidebarStyles from './SidebarStyles'
import filterMeta from '../../local_modules/filterMeta'
import searchMeta from '../../local_modules/searchMeta'
import sortMeta from '../../local_modules/sortMeta'
import { SidebarProps } from '../../types/types'

const Sidebar = (props: SidebarProps) => {
  const theme = useTheme()
  const classes = SidebarStyles(theme)

  let meta = [...props.meta]

  if (props.filterActive) {
    meta = filterMeta(props.meta, props.filterShow)
  }
  if (props.searchText) {
    meta = searchMeta(props.meta, props.cardsCurrent, props.searchText)
  }

  meta = sortMeta(meta, props.sort, props.sortDecks)

  const [open, setOpen] = useState(true)

  const handleSidebar = () => {
    setOpen(!open)
  }

  const openClassName = open ? 'open' : ''

  // menu-button needs to be outside container div for z-index to be visible above header
  return (
    <>
      <div css={classes.menuButton} onClick={handleSidebar}>
        <img
          css={classes.menuButtonImg}
          src={open ? menuOpenIcon : menuIcon}
          alt="Menu"
        />
      </div>
      <div css={classes.containerSidebar} className={openClassName}>
        <div css={classes.sidebar} className={openClassName}>
          <Sorter
            sortOptions={props.sortOptions}
            handleSortChange={props.handleSortChange}
            sort={props.sort}
          />
          <hr className="hrSolid" />
          <Search
            searchText={props.searchText}
            handleSearch={props.handleSearch}
          />
          <hr className="hrSolid" />
          {props.filterShow && (
            <>
              <Filter
                filterActive={props.filterActive}
                setFilterActive={props.setFilterActive}
                filterLabel={props.filterLabel}
              />
              <hr className="hrSolid" />
            </>
          )}
          <div css={classes.sidebarScroller}>
            {/* <Suspense fallback={<div>Loading...</div>}> */}
            <Menu
              meta={meta}
              activeDeck={props.activeDeck}
              sort={props.sort}
              handleDeckChange={props.handleDeckChange}
            />
            {/* </Suspense> */}
          </div>
        </div>
        <div
          css={classes.sidebarCloser}
          className={openClassName}
          onClick={handleSidebar}
        ></div>
      </div>
    </>
  )
}

export default Sidebar
