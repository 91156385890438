import { CSSObject, Theme } from '@emotion/react'

const DeckStyles = (theme: Theme): CSSObject => {
  return {
    containerDeckFooter: {
      backgroundColor: theme.backgroundColorDeck, // match Footer color in dark theme
      position: 'relative',
      top: 0,
      paddingTop: 44, // for header; use padding-top for header hiding onScroll
      left: 0,
      height: 'calc(100% - 44px)', // needs to be %, not vh, should match top/header height
      overflowX: 'hidden',
      overflowY: 'scroll',
      scrollbarColor: `${theme.scrollbarColorThumb} ${theme.scrollbarColorTrack}`, // firefox
      scrollbarWidth: 'thin', // firefox
      '@media only screen and (min-width: 768px)': {
        top: 55, // for header
        height: 'calc(100% - 55px)', // needs to be %, not vh, should match top/header height
        padding: 0,
        '&::-webkit-scrollbar': {
          backgroundColor: theme.backgroundColor,
          height: theme.remHalf,
          width: theme.remHalf,
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: theme.scrollbarColorThumb,
          height: theme.remHalf,
          width: theme.remHalf,
          border: theme.scrollbarBorderThumb,
        },
        '&::-webkit-scrollbar-track': {
          backgroundColor: theme.scrollbarColorTrack,
          height: theme.remHalf,
          width: theme.remHalf,
          border: theme.scrollbarBorderTrack,
        },
      },
      '@media only screen and (min-width: 992px)': {
        top: 69, // for header
        left: 0,
        height: 'calc(100% - 69px)', // needs to be %, not vh, should match top/header height
      },
    },
    deck: {
      position: 'relative',
      margin: 0,
      padding: 0,
      backgroundColor: theme.backgroundColorDeck,
      scrollMargin: '45px', // must be string with 'px': jss doesn't convert the integer to string value
      '@media only screen and (min-width: 768px)': {
        padding: '0 0 0 190px', // add width of sidebar, to center deck
      },
      '@media only screen and (min-width: 992px)': {
        padding: '0 0 0 220px', // add width of sidebar, to center deck
      },
    },
    // loading: {
    //   position: 'absolute',
    //   top: 0,
    //   left: 0,
    //   height: '100%',
    //   width: '100%',
    //   padding: 0,
    //   margin: 0,
    //   backgroundColor: 'rgba(0, 0, 0, .3)',
    //   zIndex: 10,
    //   display: 'flex',
    //   justifyContent: 'center',
    //   alignItems: 'center',
    // },
  }
}

export default DeckStyles
