import { CSSObject, Theme } from '@emotion/react'

const MenuStyles = (theme: Theme): CSSObject => {
  // Set border sizes and colors for HS Classes in Menu
  // Note: In current theme, Mobile and Desktop widths are identical; this might change in future
  const makeHsClassStyles = (platform: 'Mobile' | 'Desktop') => {
    // => 'Mobile' or 'Desktop'
    const hsClasses = [
      'deathknight',
      'demonhunter',
      'druid',
      'hunter',
      'mage',
      'paladin',
      'priest',
      'rogue',
      'shaman',
      'warlock',
      'warrior',
      'featured', // custom
      'player', // custom
      'grandmaster', // custom
      'master', // custom
    ]
    const menuSubActiveStyles: Record<string, any> = {}
    for (const hsClassName of hsClasses) {
      const key = '&.' + hsClassName
      menuSubActiveStyles[key] = {
        borderLeft: `${theme['borderMenu' + platform + 'Width']} solid 
            ${theme[hsClassName]}`,
        // borderRight: `${theme['borderMenu' + platform + 'WidthThin']} solid
        //     ${theme[hsClassName]}`,
        // backgroundColor: theme.backgroundColorLegendary,
        borderTop: theme.borderTransp,
        borderBottom: theme.borderTransp,
        // borderTop: theme.borderMenuTop,
        // borderBottom: theme.borderMenuBottom,
        // boxShadow: '0 0 30px #000',
        // borderBottom: `${theme['borderMenu' + platform + 'Width']} solid ${
        //   theme[hsClassName]
        // }`,
      }
    }
    return menuSubActiveStyles
  }
  const menuSubActiveStylesMobile = makeHsClassStyles('Mobile')
  const menuSubActiveStylesDesktop = makeHsClassStyles('Desktop')

  return {
    menu: {
      label: 'menu',
      display: 'block',
      padding: `0 0 3rem 0`, // important: padding must be set here for spacing at end of menus
    },

    menuTopBranch: {
      label: 'menu-top-branch',
      // position: 'relative',
      padding: `${theme.remQuarter} 0 ${theme.remQuarter} 0`,
      backgroundColor: theme.backgroundColor,
      borderLeft: `${theme.borderMenuMobileWidth} solid ${theme.backgroundColor}`,
      // borderBottom: '1px solid rgba(0, 0, 0, .1)',
      '&.open': menuSubActiveStylesMobile,
      '@media only screen and (min-width: 992px)': {
        borderLeft: `${theme.borderMenuDesktopWidth} solid ${theme.backgroundColor}`,
        '&.open': menuSubActiveStylesDesktop,
      },
      // border on top of first menu looks awkward
      '&.open:first-of-type': { borderTop: 0 },
    },

    menuTreeItem: {
      label: 'menu-tree-item',
      padding: `0 0 0 ${theme.remHalf}`,
      overflow: 'hidden',
    },

    menuTreeItemChildren: {
      label: 'menu-tree-item-children',
      maxHeight: 0,
      // position: 'relative',
      // visibility: 'hidden',
      // maxHeight: 0,
      // transition: 'all 5s ease-out',
      // '&.open': {
      // visibility: 'visible',
      // maxHeight: 800, // FIXME: Is there a better way to open menuSubDeck?
      // transition: 'all 5s ease-out', //FIXME: transition out is not working
      // },
    },

    loading: {
      padding: `${theme.remHalf} 0 0 ${theme.remFull}`,
      '@media only screen and (min-width: 992px)': {
        fontSize: theme.fontDesktopButtonSize,
        padding: `${theme.remHalf} 0 0 1.25rem`,
      },
    },
  }
}

export default MenuStyles
