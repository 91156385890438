/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react'

import dropDownArrow from '../images/arrow_drop_down.svg'

import { CSSObject, Theme } from '@emotion/react'
import { MenuSorterProps } from '../../types/types'

const SorterStyles = (theme: Theme): CSSObject => {
  return {
    menuSorter: {
      color: theme.fontColor,
      margin: `${theme.remQuarter} 0 0 ${theme.remFull}`,
      padding: `${theme.remQuarter} 0 ${theme.remEighth} 0`,
      '@media only screen and (min-width: 992px)': {
        margin: `${theme.remEighth} 0 0 ${theme.remTwothird}`,
        padding: `${theme.remHalf} 0 ${theme.remQuarter} ${theme.remHalf}`,
        fontSize: theme.fontDesktopButtonSize,
      },
    },
    sorterSelect: {
      color: theme.fontColor,
      fontFamily: theme.fontStackBody,
      fontSize: theme.fontButtonSize,
      // fontWeight: 'bold',
      cursor: 'pointer',
      border: theme.border,
      margin: `0 0 0 ${theme.remQuarter}`,
      padding: `${theme.remQuarter} 1.75rem ${theme.remQuarter} ${theme.remHalf}`,
      backgroundColor: theme.backgroundColorHighlight,
      backgroundImage: `url(${dropDownArrow})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right -1px top -5px',
      backgroundSize: 30,
      // filter: theme.filter,
      // backgroundSize: 'cover',
      WebkitAppearance: 'none',
      '&:focus': {
        outline: 0,
      },
      '@media only screen and (min-width: 992px)': {
        fontSize: theme.fontDesktopButtonSize,
        backgroundPosition: 'right -.125rem top -4px',
      },
    },
    // dropDownArrow: {
    //   margin: 0,
    //   padding: 0,
    // },
    sortCallout: {
      textAlign: 'center',
      border: theme.borderLink,
      backgroundColor: theme.backgroundColorHighlight,
      padding: `${theme.remHalf} ${theme.remHalf} ${theme.remHalf} ${theme.remHalf}`,
      margin: `${theme.remEighth} ${theme.remFull} ${theme.remThreefourth} 0`,
    },
    menuSorterHeader: {
      margin: `0 ${theme.remQuarter} 0 0`,
    },
  }
}

const MenuSorter = (props: MenuSorterProps) => {
  const theme = useTheme()
  const classes = SorterStyles(theme)

  const sortOptions = props.sortOptions
  const sortLinks = sortOptions.map((opt, i) => {
    return (
      <option key={i} value={opt}>
        {opt}
      </option>
    )
  })
  return (
    <div css={classes.menuSorter}>
      {/* 
        When enabling callout, adjust sidebarScroller height in SidebarStyles.js
      */}
      {/* <div css={classes.sortCallout}>
        Sort by 'Master' for top 8 players from Ruins of Alterac
      </div> */}
      <label css={classes.menuSorterHeader} htmlFor="sorter">
        Sort:{' '}
      </label>
      <select
        id="sorter"
        css={classes.sorterSelect}
        value={props.sort}
        onChange={props.handleSortChange}
      >
        {sortLinks}
      </select>
    </div>
  )
}

export default MenuSorter
