import sortMeta from './sortMeta'
import { Deck, Meta, Sort, SortDecks } from '../types/types'

const initDeck = (meta: Meta, sort: Sort, sortDecks: SortDecks): Deck => {
  // archClass Player is set in meta-streamer.json
  const srcIsMeta = sort !== 'Streamer' && sort !== 'Master'
  const sortedMeta = sortMeta(meta, sort, sortDecks)
  const deck = {
    // test for archetype or deck
    name: srcIsMeta ? sortedMeta[0].name : sortedMeta[0].decks[0].name,
    deckcode: srcIsMeta
      ? sortedMeta[0].deckcode
      : sortedMeta[0].decks[0].deckcode,
    // player decks have src and url
    // first meta deck is always archetype: no source, no url
    src: srcIsMeta ? undefined : sortedMeta[0].decks[0].src,
    url: srcIsMeta ? undefined : sortedMeta[0].decks[0].url,
    archetype: sortedMeta[0].name,
    archClass: sortedMeta[0].archClass,
    cache: srcIsMeta ? sortedMeta[0].cache : sortedMeta[0].decks[0].cache,
    concurrence: srcIsMeta ? undefined : sortedMeta[0].decks[0].concurrence,
  }
  return deck
}

export default initDeck
