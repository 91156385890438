import { Theme } from '@emotion/react'

const darkBorderColor = '#000000'
const lightBorderColor = '#baafa6'
const transpBorderColorLight = 'rgba(0, 0, 0, .1)'
const transpBorderColorDark = 'rgba(0, 0, 0, .2)'
const borderLinkColor = '#3055cf'

const spacing = {
  remSixteenth: '.06rem',
  remEighth: '.125rem',
  remQuarter: '.25rem',
  remHalf: '.5rem',
  remTwothird: '.62rem',
  remThreefourth: '.75rem',
  remFull: '1rem',
  borderMenuMobileWidth: '8px',
  borderMenuMobileWidthThin: '4px',
  borderMenuDesktopWidth: '8px',
  borderMenuDesktopWidthThin: '4px',
}

const fonts = {
  fontStackBody: 'ropa-sans-pro, sans-serif',
  fontBaseSize: 12,
  fontSmallSize: '.62rem',

  fontBodyWeight: 400,
  fontBodyWeightBold: 700,

  fontStackTitle: 'mono45-headline, sans-serif',
  fontTitleWeight: 300,

  fontTitleSize: '1.5rem',
  fontSubheadSize: '1.2rem',

  fontButtonSize: '.75rem',

  fontDesktopTitleSize: '2.25rem',
  fontDesktopSubheadSize: '1.5rem',
  fontDesktopButtonSize: '.86rem',
}

const classMenuColors = {
  deathknight: '#6accd4',
  demonhunter: '#a0cf4f',
  druid: '#6c4628',
  hunter: '#21671b',
  mage: '#4b60a5',
  paladin: '#d0b727',
  priest: '#b8b8b8',
  rogue: '#222222',
  shaman: '#333a72',
  warlock: '#633871',
  warrior: '#902c1f',
  player: '#64128a',
  grandmaster: '#64128a',
  master: '#64128a',
  featured: '#241d17',
}

const theme: Theme = {
  light: {
    ...spacing,
    ...fonts,
    ...classMenuColors,
    fontColor: '#2f241b',
    fontColorMedium: '#7a7871',
    fontColorDark: '#2f241b',
    fontColorLight: '#d1cdc2',
    fontColorLink: '#294fcc',
    fontColorHover: '#294fcc',
    fontColorSelected: '#294fcc',
    fontColorLegendary: '#e8a35a',

    backgroundColor: '#ebe6da',
    backgroundColorLight: '#ffffff',
    backgroundColorMedium: '#9c9a92',
    backgroundColorDark: '#cabeb4',
    backgroundColorHighlight: '#f6f1e4',

    backgroundColorActive: '#662729',
    backgroundColorHover: '#9fafe6',
    backgroundColorHoverLight: '#f1f3ff',

    backgroundColorHeader: '#ffffff',
    backgroundColorDeck: '#dcd7cc',
    backgroundColorFooter: '#dcd7cc',
    backgroundColorTile: '#ffffff',

    backgroundColorCommon: '#bdbdbd',
    backgroundColorRare: '#a4daff',
    backgroundColorEpic: '#e799f2',
    backgroundColorLegendary: '#e5a561',

    borderColor: lightBorderColor,
    border: `1px solid ${lightBorderColor}`,
    borderDark: `2px solid ${darkBorderColor}`,
    borderTransp: `1px solid ${transpBorderColorLight}`,
    borderMenuTop: `1px solid #bdb9ad`,
    borderMenuBottom: `4px solid #dfdacf`,
    borderFontColor: `1px solid #2f241b`,
    borderSelect: `1px solid ${lightBorderColor}`,

    borderLink: `1px solid ${borderLinkColor}`,

    scrollbarColorThumb: '#bdb9ad',
    scrollbarBorderThumb: '1px solid #9c9a92',
    scrollbarColorTrack: '#dfdacf',
    scrollbarBorderTrack: '1px solid #cabeb4',

    filterLink:
      'invert(50%) sepia(11%) saturate(3099%) hue-rotate(191deg) brightness(87%) contrast(90%)',
    filter: 'none',
    filterDropShadow: 'drop-shadow(0 0 2px rgba(0, 0, 0, .5))',
    filterDropShadowHover: 'drop-shadow(0 0 4px rgba(50, 100, 255, .8))',
    filterDropShadowFullSizeImage: 'drop-shadow(0 0 26px rgba(0, 0, 0, 0.4))',
  },
  dark: {
    ...spacing,
    ...fonts,
    ...classMenuColors,
    fontColor: '#d1cdc2',
    fontColorMedium: '#b3afa6',
    fontColorDark: '#2f241b',
    fontColorLight: '#b3afa6',
    fontColorLink: '#91aaff',
    fontColorHover: '#91aaff',
    // fontColorHover: '#df9655',
    fontColorSelected: '#91aaff',
    fontColorLegendary: '#df8e2f',

    backgroundColor: '#333331',
    backgroundColorLight: '#c6c6c4',
    backgroundColorMedium: '#343432',
    backgroundColorDark: '#131311',
    backgroundColorHighlight: '#2c2c2a',

    backgroundColorActive: '#662729',
    backgroundColorHover: '#4f5979',
    backgroundColorHoverLight: '#f1f3ff',

    backgroundColorHeader: '#181816',
    backgroundColorDeck: '#272725',
    backgroundColorFooter: '#272725',
    backgroundColorTile: '#343432',

    backgroundColorCommon: '#484848',
    backgroundColorRare: '#465ca1',
    backgroundColorEpic: '#742f75',
    backgroundColorLegendary: '#df8e2f',

    borderColor: darkBorderColor,
    border: `1px solid ${darkBorderColor}`,
    borderDark: `2px solid ${darkBorderColor}`,
    borderTransp: `1px solid ${transpBorderColorDark}`,
    borderThick: `4px solid ${darkBorderColor}`,
    borderMenuTop: `1px solid #555555`,
    borderMenuBottom: `4px solid #222222`,
    borderFontColor: `1px solid #cccccc`, // font color
    borderSelect: `1px solid #cccccc`,

    borderLink: `1px solid #4f5979`,

    scrollbarColorThumb: '#555555',
    scrollbarBorderThumb: '1px solid #111111',
    scrollbarColorTrack: '#222222',
    scrollbarBorderTrack: '1px solid #111111',

    filter:
      'invert(100%) sepia(75%) saturate(30%) hue-rotate(346deg) brightness(82%) contrast(100%)',
    filterLink:
      'invert(65%) sepia(11%) saturate(1300%) hue-rotate(197deg) brightness(100%) contrast(100%)',
    filterDropShadow: 'drop-shadow(0 0 2px rgba(0, 0, 0, .5))',
    filterDropShadowHover: 'drop-shadow(0 0 4px rgba(50, 100, 255, .6))',
    filterDropShadowFullSizeImage: 'drop-shadow(0 0 26px rgba(0, 0, 0, 0.8))',
  },
}

export default theme
