import { useState, lazy, Suspense } from 'react'
/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react'
import settingsIcon from './images/settings-icon.svg'
import SettingsStyles from './SettingsStyles'

import { SettingsProps } from '../types/types'

const SettingsModal = lazy(() => import('./SettingsModal'))

const Settings = ({
  deckView,
  cardSize,
  setLocalCardSize,
  sortDecks,
  themeFlavor,
  windowWidth,
  handleSetDeckView,
  setFooterVisible,
  handleCardSizeChange,
  handleSortDecksChange,
  handleSetTheme,
}: SettingsProps) => {
  const theme = useTheme()
  const classes = SettingsStyles(theme)

  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setLocalCardSize()
  }

  const showCardSizeRange =
    deckView === 'cards' || (deckView !== 'tiles' && windowWidth >= 768)

  return (
    <>
      <div css={classes.buttonSettingsOpen} onClick={() => handleOpen()}>
        {/* <h4
          css={
            props.newUser
              ? classes.buttonSettingsOpenHeader + ' newUser'
              : classes.buttonSettingsOpenHeader
          }
        >
          Settings
        </h4> */}
        <h4 css={classes.buttonSettingsOpenHeader}>SETTINGS</h4>
        {/* <img
          css={
            props.newUser
              ? classes.buttonSettingsOpenImg + ' newUser'
              : classes.buttonSettingsOpenImg
          }
          src={settingsIcon}
          alt="Settings"
        /> */}
        <img
          css={classes.buttonSettingsOpenImg}
          src={settingsIcon}
          alt="Settings"
        />
      </div>
      <Suspense fallback={null}>
        {open && (
          <SettingsModal
            deckView={deckView}
            cardSize={cardSize}
            sortDecks={sortDecks}
            themeFlavor={themeFlavor}
            windowWidth={windowWidth}
            handleSetDeckView={handleSetDeckView}
            setFooterVisible={setFooterVisible}
            handleCardSizeChange={handleCardSizeChange}
            handleSortDecksChange={handleSortDecksChange}
            handleSetTheme={handleSetTheme}
            handleClose={handleClose}
            showCardSizeRange={showCardSizeRange}
            open={open}
          />
        )}
      </Suspense>
      <div
        css={open ? classes.settingsCloserOpen : classes.settingsCloser}
        onClick={() => handleClose()}
      ></div>
    </>
  )
}

export default Settings
