import { Suspense, useEffect, lazy /*, useState */ } from 'react'
/** @jsxImportSource @emotion/react */
import { useTheme } from '@emotion/react'
// import throttle from 'lodash/throttle'

import Loading from '../Loading'
import DeckHeader from './DeckHeader'
import CopyDeckcode from './CopyDeckcode'
// import BackToTop from './BackToTop'

import DeckStyles from './DeckStyles'

import handleModelDeck from '../../local_modules/modelDeckHandle'

import { DeckViewerProps } from '../../types/types'

const ViewDeckCardSet = lazy(() => import('./ViewDeckCardSet'))
const ViewDeckTiles = lazy(() => import('./ViewDeckTiles'))
const Footer = lazy(() => import('./Footer'))

const Deck = (props: DeckViewerProps) => {
  /**
   * Model Deck
   */
  const modelDeck = handleModelDeck(
    props.cardsCurrent,
    props.activeDeck.deckcode
  )

  // go back to top of page when a deck loads
  useEffect(() => {
    const scrollTo = () => {
      const deckTop = document.getElementById('deck')
      deckTop?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
    scrollTo()
  }, [props.activeDeck])

  /**
   * Hide Header when scrolling
   */
  // const [scrollPosition, setScrollPosition] = useState(0);

  // const handleScroll = (e) => {
  //   const pos = e.target.scrollTop;
  //   if (pos > 75 && pos > scrollPosition) props.setHeaderVisible(false);
  //   else if (pos < 75 || pos < scrollPosition - 150)
  //     props.setHeaderVisible(true);
  //   setScrollPosition(pos);
  // };

  // const handleScrollThrottled = throttle((e) => handleScroll(e), 0)

  // Clean up
  // useEffect(() => {
  //   return () => {
  //     handleScrollThrottled.cancel()
  //   }
  // })

  const theme = useTheme()
  const classes = DeckStyles(theme)

  return (
    <div
      css={classes.containerDeckFooter} /* onScroll={(e) => handleScroll(e)} */
    >
      <Suspense
        fallback={
          <div id="deck" css={classes.deck}>
            <Loading />
            <DeckHeader
              themeFlavor={props.themeFlavor}
              loading={true}
              activeDeck={props.activeDeck}
              sort={props.sort}
            />
          </div>
        }
      >
        <div id="deck" css={classes.deck}>
          {props.activeDeck &&
            /*archetypes shouldn't show CopyDeckcode;
             archetype activeDecks don't have .src */
            (props.activeDeck.src || props.sort === 'Streamer') && (
              <CopyDeckcode activeDeck={props.activeDeck} />
            )}
          <DeckHeader
            themeFlavor={props.themeFlavor}
            activeDeck={props.activeDeck}
            sort={props.sort}
          />
          {(props.windowWidth < 768 && props.deckView === 'default') ||
          props.deckView === 'tiles' ? (
            <ViewDeckTiles
              activeDeck={props.activeDeck}
              modelDeck={modelDeck}
              setFooterVisible={props.setFooterVisible}
            />
          ) : (
            <ViewDeckCardSet
              storedCardSizeFactor={props.cardSize}
              modelDeck={modelDeck}
              setFooterVisible={props.setFooterVisible}
            />
          )}
          {/* <BackToTop scrollToRef={ref} /> */}
        </div>
        {props.footerVisible && (
          <Suspense fallback={null}>
            <Footer
              metaDate={props.metaDate}
              rankStartDate={props.rankStartDate}
            />
          </Suspense>
        )}
      </Suspense>
    </div>
  )
}

export default Deck
