import { CSSObject, Theme } from '@emotion/react'

const HeaderStyles = (theme: Theme): CSSObject => {
  return {
    header: {
      position: 'fixed',
      zIndex: 4,
      top: 0,
      left: 0,
      width: '100%',
      height: 44,
      margin: 0,
      padding: 0,
      border: 0,
      borderBottom: theme.border,
      boxShadow: `0 0 10px rgba(0, 0, 0, .3)`,
      display: 'grid',
      gridTemplateColumns: 'auto 50px',
      color: theme.fontColorDark,
      backgroundColor: theme.backgroundColorHeader,
      transform: 'translateY(0)',
      transition: 'transform 0.2s ease',
      '&.hidden': {
        transform: ' translateY(-110%)',
      },

      '@media only screen and (min-width: 768px)': {
        height: 54,
        margin: 0,
        transform: 'none',
        transition: 'unset',
        '&.hidden': {
          transform: 'none',
        },
      },
      '@media only screen and (min-width: 992px)': {
        display: 'block',
        height: 68,
        margin: 0,
        padding: `${theme.remQuarter} 0 0 0`,
      },
    },
    headerLogo: {
      justifySelf: 'start',
      alignSelf: 'center',
      margin: 0,
      padding: '.1rem 0 0 0',
      '@media only screen and (min-width: 768px)': {
        margin: 0,
        padding: '.1rem 0 0 0',
      },
      '@media only screen and (min-width: 992px)': {
        height: 60,
        margin: `.1rem 0 0 ${theme.remHalf}`,
        padding: 0,
      },
    },
    headerLogoImg: {
      height: 36,
      margin: 0,
      padding: `0 0 0 6px`,
      filter: `${theme.filter}`,
      '@media only screen and (min-width: 768px)': {
        height: 40,
        margin: 0,
        padding: 0,
      },
      '@media only screen and (min-width: 992px)': {
        height: 60,
        margin: 0,
        padding: 0,
      },
    },
  }
}

export default HeaderStyles
