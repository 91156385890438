import { Meta, FilterShow } from '../types/types'

const filterMeta = (metaUnfiltered: Meta, filterShow: FilterShow) => {
  let meta = []
  /**
   * Filter meta; imperative style for better performance
   */
  for (const archetype of metaUnfiltered) {
    const decksFiltered = []
    for (const deck of archetype.decks) {
      // if (
      //   filterShow !== 'miniset' &&
      //   filterShow !== 'newExpansion'
      // ) {
      //   // date
      //   const dateLimit = Number(
      //     filterShow.replace(/[-T:]/g, '').substring(0, 14)
      //   )
      //   const deckDate = Number(
      //     deck.cache.replace(/[-T:]/g, '').substring(0, 14)
      //   )

      //   if (deckDate >= dateLimit) {
      //     decksFiltered.push(deck)
      //   }
      // } else
      if (filterShow !== null && deck[filterShow]) {
        // from new set
        decksFiltered.push(deck)
      }
    }
    if (decksFiltered.length) {
      const archetypeFiltered = { ...archetype }
      archetypeFiltered.decks = [...decksFiltered]
      meta.push(archetypeFiltered)
    }
  }
  metaUnfiltered = [...meta]
  return meta
}

export default filterMeta
